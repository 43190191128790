import { App, Form } from 'antd';
import { StockApiService } from 'core/api';
import { IHearingAidDao } from 'core/api/types';
import { IStockHearingAidDao } from 'core/api/types/stock.interface';
import { StockStatus, StockStatusData, StockStatusOptions } from 'core/constants/stock-status';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import {
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';

export interface IAddEditStockHearingAidFormOutput {
  manufacturer: string;
  model: string;
  style: string;
  power: string;
  colour: string;
  serialNumber?: string;
  cost?: number;
  warranty?: string;
  status?: StockStatus;
  orderedDate?: Dayjs;
  stockAddedDate?: Dayjs;
  location?: string;
}

interface IAddEditStockHearingAidForm {
  tableKey: string;
  stock?: IStockHearingAidDao;
}

const AddEditStockHearingAidForm = ({ tableKey, stock }: IAddEditStockHearingAidForm) => {
  // Hooks
  const dialog = useDialog();
  const hearingAidState = useSelector(OrganisationSettingsSlice.selectHearingAids);
  const { t } = useTranslation();
  const { userData } = useUserState();
  const { message } = App.useApp();
  const table = useAComponentPortal(tableKey);
  const counts = useAComponentPortal('stockManagementCounts');

  // State
  const [submitting, setSubmitting] = useState(false);
  const [matchedAid, setMatchedAid] = useState<IDomainOrganisationDataType<IHearingAidDao>>();

  // Form
  const [form] = Form.useForm();
  const [{ manufacturer, model, status }, setWatchedValues] = useState<Partial<IAddEditStockHearingAidFormOutput>>(
    stock
      ? {
          manufacturer: stock.manufacturer,
          model: stock.model,
          style: stock.style,
        }
      : {}
  );

  // Variables
  const manufacturersList = [
    ...new Set(
      hearingAidState?.data.filter((hearingAid) => !hearingAid.deleted).map((hearingAid) => hearingAid.manufacturer)
    ),
  ];
  const modelList = useMemo(() => {
    return [
      ...new Set(
        hearingAidState?.data
          .filter((hearingAid) => !hearingAid.deleted && hearingAid.manufacturer === manufacturer)
          .map((hearingAid) => hearingAid.model)
      ),
    ];
  }, [hearingAidState?.data, manufacturer]);

  const styleList = useMemo(
    () => [
      ...new Set(
        hearingAidState?.data
          .filter(
            (hearingAid) =>
              !hearingAid.deleted && hearingAid.manufacturer === manufacturer && hearingAid.model === model
          )
          .map((hearingAid) => hearingAid.style)
      ),
    ],
    [hearingAidState?.data, manufacturer, model]
  );
  const creating = !stock;

  useEffect(() => {
    if (!creating) {
      setMatchedAid(hearingAidState?.data.find((hearingAid) => hearingAid.uid === stock?.hearingAidUid));
    }
  }, [creating, hearingAidState?.data, stock?.hearingAidUid]);

  const fields: ISharedField[] = [
    {
      fieldKey: 'manufacturer',
      control: ControlType.Select,
      options: manufacturersList.map((manufacturer) => ({ label: manufacturer, value: manufacturer })),
      label: t('stock_management.add_edit_stock.form.manufacturer'),
      required: true,
    },
    {
      fieldKey: 'model',
      control: ControlType.Select,
      options: modelList.map((model) => ({ label: model, value: model })),
      label: t('stock_management.add_edit_stock.form.model'),
      required: true,
      hidden: !manufacturer,
    },
    {
      fieldKey: 'style',
      control: ControlType.Select,
      options: styleList.map((model) => ({ label: model, value: model })),
      label: t('stock_management.add_edit_stock.form.style'),
      required: true,
      hidden: !manufacturer || !model,
    },
    {
      fieldKey: 'power',
      control: ControlType.Select,
      options: matchedAid?.power.map((power) => ({ label: power, value: power })) ?? [],
      label: t('stock_management.add_edit_stock.form.power'),
      required: true,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'colour',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.colour'),
      required: true,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'serialNumber',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.serial_number'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'cost',
      control: ControlType.NumberField,
      label: t('stock_management.add_edit_stock.form.cost'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'warranty',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.warranty'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'location',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('stock_management.add_edit_stock.form.location'),
      required: false,
      hidden: !matchedAid,
    },
    {
      fieldKey: 'status',
      control: ControlType.Select,
      options: StockStatusOptions.map((status) => {
        const statusData = StockStatusData[status];
        return { label: t(statusData.translationLabelKey), value: statusData.value };
      }),
      label: t('stock_management.add_edit_stock.form.status'),
      required: true,
      hidden: !matchedAid || stock !== undefined,
    },
    {
      fieldKey: 'orderedDate',
      control: ControlType.DatePicker,
      maxDate: dayjs(),
      label: t('stock_management.add_edit_stock.form.ordered_date'),
      required: true,
      hidden: !matchedAid || !status || status === StockStatus.NEEDS_ORDERING,
      fullWidth: true,
    },
    {
      fieldKey: 'stockAddedDate',
      control: ControlType.DatePicker,
      maxDate: dayjs(),
      label: t('stock_management.add_edit_stock.form.stock_added_date'),
      required: true,
      hidden: !matchedAid || !status || status === StockStatus.NEEDS_ORDERING || status === StockStatus.ORDERED,
      fullWidth: true,
    },
  ];

  const submit = async (data: IAddEditStockHearingAidFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const { serialNumber, warranty, orderedDate, stockAddedDate, cost, location, status, ...rest } = data;
      const basePayload = {
        ...rest,
        updated: userTimestamp,
        hearingAidUid: matchedAid!.uid,
        ...(location && { location }),
        ...(cost && { cost }),
        ...(serialNumber && { serialNumber }),
        ...(warranty && { warranty }),
        ...(orderedDate && { orderedDate: Timestamp.fromDate(orderedDate.toDate()) }),
        ...(stockAddedDate && { stockAddedDate: Timestamp.fromDate(stockAddedDate.toDate()) }),
      };
      if (creating) {
        await StockApiService.set({
          ...rest,
          ...basePayload,
          status: status!,
          organisationUid: userData.organisationUid,
          type: 'hearingAid',
          uid: uuidv4(),
          created: userTimestamp,
        });
      } else {
        await StockApiService.update(stock!.uid, basePayload);
      }
      setSubmitting(false);
      dialog?.closeDialog();
      message.success(
        t(
          creating ? 'stock_management.add_edit_stock.create.success' : 'stock_management.add_edit_stock.update.success'
        )
      );
      table?.exposedFunction();
      counts?.exposedFunction();
    } catch (error) {
      setSubmitting(false);
      message.error(
        t(creating ? 'stock_management.add_edit_stock.create.error' : 'stock_management.add_edit_stock.update.error')
      );
      sentryCaptureException(error, creating ? 'Add hearing aid stock' : 'Update hearing aid stock', userData);
    }
  };

  const resetFields = useCallback(
    (fields: string[]) => {
      const values = fields.reduce((acc, field) => ({ ...acc, [field]: undefined }), {});
      form.setFieldsValue(values);
      setWatchedValues((prev) => ({ ...prev, ...values }));
    },
    [form]
  );

  const onFormChange = (change: Partial<IAddEditStockHearingAidFormOutput>) => {
    setWatchedValues((prev) => ({ ...prev, ...change }));
    const [key, value] = Object.entries(change)[0];
    switch (key) {
      case 'manufacturer':
        resetFields(['model', 'style', 'power']);
        setMatchedAid(undefined);
        break;
      case 'model':
        resetFields(['style', 'power']);
        setMatchedAid(undefined);
        break;
      case 'style': {
        if (manufacturer && model) {
          const matchedAid = hearingAidState?.data.find(
            (hearingAid) =>
              hearingAid.manufacturer === manufacturer && hearingAid.model === model && hearingAid.style === value
          );
          setMatchedAid(matchedAid);
          if (matchedAid) {
            form.setFieldsValue({
              cost: matchedAid.rrp,
              warranty: matchedAid.warranty,
            });
          }
        }
        resetFields(['power']);
      }
    }
  };

  return (
    <SharedForm<IAddEditStockHearingAidFormOutput>
      formInstance={form}
      className=''
      onFinish={submit}
      onChange={onFormChange}
      fields={fields}
      submitting={submitting}
      cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
      name='add-edit-hearing-aid-stock-form'
      existingValue={
        stock
          ? {
              ...stock,
              orderedDate: dayjs(stock.orderedDate?.toDate()),
              stockAddedDate: dayjs(stock.stockAddedDate?.toDate()),
            }
          : {
              stockAddedDate: dayjs(),
            }
      }
    />
  );
};

export default AddEditStockHearingAidForm;
