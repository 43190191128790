import SharedDialogBase from './dialog-base';
import { ControlType } from 'core/enums/control-type';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import { App, Form } from 'antd';

interface ITextInputDialog {
  title: string;
  fieldKey: string;
  defaultValue?: string[];
  onFinish: (value: string[]) => void;
}

const SetCustomOptionsDialog = ({ title, fieldKey, defaultValue, onFinish }: ITextInputDialog) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dialog = useDialog();
  const { message } = App.useApp();

  const SetCustomOptionsFormFields: ISharedField[] = [
    {
      fieldKey,
      control: ControlType.SelectMultipleTags,
      label: title,
      onChange: (values: string[]) => {
        const normalizedValues = values.reduce((unique: string[], current) => {
          const normalizedCurrent = current.trim();
          const isDuplicate = unique.some((existing) => existing.toLowerCase() === normalizedCurrent.toLowerCase());

          if (!isDuplicate && normalizedCurrent) {
            unique.push(normalizedCurrent);
          } else if (isDuplicate && normalizedCurrent) {
            message.warning(t('form_settings.patient_form.duplicate_option_not_added'));
          }

          return unique;
        }, []);

        form.setFieldValue(fieldKey, normalizedValues);
      },
      required: false,
      extra: <em className='text-gray-500 text-sm'>{t('form_settings.patient_form.type_to_add_new_options')}</em>,
    },
  ];

  const customContent = () => {
    return (
      <SharedForm
        fields={SetCustomOptionsFormFields}
        formInstance={form}
        name='set-custom-options-form'
        className='p-4 overflow-y-auto'
        existingValue={{ [fieldKey]: defaultValue }}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        onFinish={(value: Record<string, string[]>) => {
          onFinish(value[fieldKey]);
        }}
      />
    );
  };

  return <SharedDialogBase title={title} customContentTemplate={customContent()} showButtons={false} />;
};

export default SetCustomOptionsDialog;
