import { message } from 'antd';
import { StockApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { where, orderBy, and, or } from 'firebase/firestore';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import Skarkey from 'assets/images/starkey.svg';
import clsx from 'clsx';

const PatientOverviewManufacturerWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();
  return (
    <SharedCard outerClassName='h-fit' title={t('patients.patient.patient_overview.manufacturer_widget.title')}>
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.STOCK_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const [loading, setLoading] = useState(true);
  const { userData } = useUserState();
  const [manufacturerCounts, setManufacturerCounts] = useState<{ [key: string]: number }>({});
  const manufacturerCountsEntries = Object.entries(manufacturerCounts);

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        const counts: { [key: string]: number } = {};
        snap.forEach((doc) => {
          const data = doc.data();
          counts[data.manufacturer] = (counts[data.manufacturer] ?? 0) + 1;
          setManufacturerCounts(counts);
        });
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.patient_overview.manufacturer_widget.error'));
        sentryCaptureException(error, 'Patient file fetching last transactions', userData);
      },
      [],
      {
        filter: and(
          where('organisationUid', '==', userData?.organisationUid),
          or(
            and(where('type', '==', 'hearingAid'), where('allocated.to.uid', '==', patient.uid)),
            and(where('type', '==', 'accessory'), where('allocation.patients', 'array-contains', patient.uid))
          )
        ),
        orderBy: orderBy('updated.at', 'desc'),
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [patient.uid, userData]);

  return (
    <div>
      {loading ? (
        <div
          className={clsx(
            'flex justify-between items-center p-4 border-b',
            manufacturerCountsEntries.length < 2 ? '' : 'last:border-b-0'
          )}
        >
          <SkeletonElement height='60px' width='100px' />
          <SkeletonElement height='22px' width='80px' />
        </div>
      ) : (
        <div>
          {manufacturerCountsEntries.length === 0 && (
            <p className='p-4 text-gray-300 text-center'>
              {t('patients.patient.patient_overview.manufacturer_widget.no_products')}
            </p>
          )}
          {manufacturerCountsEntries
            .filter(([_, count]) => count > 0)
            .map(([manufacturer, count]) => (
              <div
                key={manufacturer}
                className={clsx('flex justify-between items-center p-4 border-b last:border-b-0')}
              >
                {manufacturer.toLowerCase().trim() === 'starkey' ? (
                  <img src={Skarkey} alt='Starkey' className='max-h-[60px]' />
                ) : (
                  <p className='text-xl font-extralight'>{manufacturer}</p>
                )}
                <p>
                  {count} {count > 1 ? 'products' : 'product'}
                </p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
export default PatientOverviewManufacturerWidget;
