import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

interface IInfoRow {
  label: string;
  value?: string | number;
}

const InfoRow = ({ label, value }: IInfoRow) => {
  const { t } = useTranslation();

  return (
    <div className='grid grid-cols-2 gap-x-px mt-2 first:mt-0'>
      <p className='label-sm grow'>{label}</p>
      <p className={clsx('body-sm text-right grow break-words', !value && 'text-gray-300')}>
        {value ?? t('common.not_provided')}
      </p>
    </div>
  );
};

export default InfoRow;
