export enum FirebaseFunctionName {
  CREATE_ADMIN = 'createAdmin',
  CREATE_ORGANISATION = 'createOrganisation',
  CREATE_ORGANISATION_OWNER = 'createOrganisationOwner',
  CREATE_MEMBER = 'createMember',
  CREATE_GROUP = 'createGroup',
  SEARCH_USER = 'searchUser',
  DELETE_USER = 'deleteUser',
  ADMIN_DELETE_USER = 'adminDeleteUser',
  SEARCH_PATIENT = 'searchPatient',
  RESET_ORGANISATION_SETTINGS = 'resetOrganisationSettings',
  GET_SIGNED_FILE_URL = 'getSignedFileUrl',
  GET_CUSTOM_FORM = 'getCustomForm',
  IMPORT_PRODUCTS_AND_SERVICES = 'importProductsAndServices',
  GET_BOOKING_WIDGET_CONFIG = 'getBookingWidgetConfig',
  GET_AVAILABLE_BOOKING_WIDGET_SLOTS = 'getAvailableBookingWidgetSlots',
  IMPORT_PATIENTS = 'importPatients',
  IMPORT_APPOINTMENTS = 'importAppointments',
  CONFIRM_BOOKING_WIDGET_APPOINTMENT = 'confirmBookingWidgetAppointment',
  EXPORT_PATIENTS = 'exportPatients',
  FETCH_PATIENT_APPOINTMENTS = 'fetchPatientAppointments',
  RESET_USER_PASSWORD = 'resetUserPassword',
  GENERATE_FITTING_RECEIPT = 'generateFittingReceipt',
  PROCESS_PATIENT_IMPORT_CSV = 'processPatientImportCSV',
  PRINT_APPOINTMENT_SCHEDULE = 'printAppointmentSchedule',
  EMAIL_PATIENT_DOCUMENT = 'emailPatientDocument',
  REGENERATE_REPAIR_FORM_PDF = 'regenerateRepairFormPdf',
  EXPORT_TRANSACTIONS = 'transactionsExport',
}
