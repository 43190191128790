import { ISharedField } from 'shared/fields/shared-fields.interface';
import { App } from 'antd';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { Dayjs } from 'dayjs';
import { TransactionApiService } from 'core/api';

interface IExportTransactionsDialogFormOutput {
  dateRange: [Dayjs, Dayjs];
}

const dateRangeFieldKey = 'dateRange';

const ExportTransactionsDialog = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const ExportTransactionsDialogFields: ISharedField[] = [
    {
      fieldKey: dateRangeFieldKey,
      control: ControlType.DateRangePicker,
      label: t('finance.export_transactions.dialog.date_range'),
      required: true,
    },
  ];

  const submit = async (data: IExportTransactionsDialogFormOutput) => {
    setSubmitting(true);

    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      const dateFrom = data.dateRange[0].format('DD-MM-YYYY');
      const dateTo = data.dateRange[1].format('DD-MM-YYYY');

      const csv = await TransactionApiService.exportTransactions({
        dateFrom,
        dateTo,
      });
      const blob = new Blob(['\ufeff', csv]);
      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = `transactions_${dateFrom}_to_${dateTo}.csv`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      message.success(t('finance.export_transactions.dialog.success.description'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('finance.export_transactions.dialog.error.description'));
      sentryCaptureException(error, 'Export transactions', userData);
    } finally {
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IExportTransactionsDialogFormOutput>
        onFinish={submit}
        fields={ExportTransactionsDialogFields}
        submitting={submitting}
        cancelButton={{
          onClick: () => dialog?.closeDialog(),
          appearance: 'text',
          labelKey: 'common.cancel',
        }}
        name='export-transactions-form'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('finance.export_transactions.dialog.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default ExportTransactionsDialog;
