import { Form } from 'antd';
import { ISharedForm } from './shared-form.interface';
import SharedButton from 'shared/button/button';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import { AlertTriangle } from 'react-feather';
import { getField } from 'shared/fields/fields.helpers';
import { Fragment } from 'react/jsx-runtime';
import { ControlType } from 'core/enums/control-type';
import { RuleObject } from 'antd/es/form';
import clsx from 'clsx';

const SharedForm = <T,>({
  formInstance,
  onFinish,
  name,
  buttonsOverride,
  cancelButton,
  buttonLabelKey = 'common.submit',
  submitting = false,
  fields,
  className = 'p-4',
  existingValue,
  initializing = false,
  onChange,
  formError,
  disabled,
  layout = 'vertical',
  requiredMark = 'optional',
}: ISharedForm<T>) => {
  const [form] = Form.useForm();

  return initializing ? (
    <div className={clsx('flex flex-col space-y-4', className)}>
      {fields
        .filter((field) => !field.hidden)
        .map((field) => (
          <div key={field.fieldKey}>
            <SkeletonElement className='mb-2' width='80px' height='17px' />
            <SkeletonElement width='100%' height='36px' />
          </div>
        ))}
    </div>
  ) : (
    <Form
      onFinish={onFinish}
      name={name}
      form={formInstance ?? form}
      layout={layout}
      variant='outlined'
      requiredMark={requiredMark}
      className={className}
      disabled={submitting || disabled}
      onValuesChange={onChange}
      initialValues={existingValue}
    >
      {fields
        .filter((field) => !field.hidden)
        .map((field) => (
          <Fragment key={field.fieldKey}>
            <Form.Item
              className={field.className}
              key={field.fieldKey}
              name={field.fieldKey}
              label={field.label}
              rules={[
                { required: field.required, message: 'This field is required' },
                ...(field.customRules ?? []),
                ...(field.control === ControlType.Signature
                  ? [
                      {
                        validator: async (_: RuleObject, value: SignaturePad) => {
                          if (!field.required) {
                            return Promise.resolve();
                          }

                          if (value && value.isEmpty()) {
                            return Promise.reject('This field is required');
                          }

                          return Promise.resolve();
                        },
                      },
                    ]
                  : []),
              ]}
              dependencies={field.dependencies}
              validateTrigger={(() => {
                if (field.control === ControlType.Signature) {
                  return 'onSubmit';
                }
                return field.validateTrigger ?? 'onChange';
              })()}
            >
              {getField(field)}
            </Form.Item>
            {field.extra && <Form.Item>{field.extra}</Form.Item>}
          </Fragment>
        ))}
      {formError && (
        <Form.Item>
          <div className='body-sm text-red-600 flex items-center'>
            <AlertTriangle className='mr-3' />
            <p>{formError}</p>
          </div>
        </Form.Item>
      )}
      {(!buttonsOverride || buttonsOverride.length > 0) && (
        <Form.Item className='m-0 pt-3'>
          <div className='flex space-x-2 justify-end'>
            {!buttonsOverride ? (
              <>
                {cancelButton && <SharedButton type='button' {...cancelButton} disabled={submitting} />}
                <SharedButton labelKey={buttonLabelKey} type='submit' appearance='primary' loading={submitting} />
              </>
            ) : (
              buttonsOverride.map((button) => <SharedButton {...button} />)
            )}
          </div>
        </Form.Item>
      )}
    </Form>
  );
};

export default SharedForm;
