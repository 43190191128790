import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import AddEditRepairDialog from './add-edit-repair-dialog';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import { IRepairDao } from 'core/api/types/repairs.interface';
import { useTheme } from 'core/providers/theme-provider';
import { Link } from 'react-router-dom';
import RepairStatusTag from './repair-status-tag';
import { RepairStatus } from 'core/constants/repair-status';
import AddEditRepairNote from './add-edit-repair-note-dialog';

const RepairsList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'repairs.repairs_list.table';
  const dialog = useDialog();
  const { primary } = useTheme();

  const headerActions = [
    {
      element: (
        <div className='space-x-2'>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.REPAIRS_CREATE], [Permission.ORGANISATION_OWNER]]}
          >
            <SharedButton
              labelKey='repairs.repairs_list.header.create_repair'
              onClick={() =>
                dialog?.openDialog(
                  <PatientSearchDialog
                    canClose
                    onSelect={(result) =>
                      dialog.replaceDialog(
                        <AddEditRepairDialog
                          tableKey={tableKey}
                          patient={{
                            uid: result.objectID,
                            fullName: result.fullName,
                          }}
                        />
                      )
                    }
                  />
                )
              }
              appearance='primary'
            />
          </SharedElementPermissionGuard>
        </div>
      ),
      key: 'createRepair',
    },
  ];

  const patientTemplate = (repair: IRepairDao) => {
    return (
      <Link to={`/patients/${repair.patient.uid}`} style={{ color: primary.bg }}>
        {repair.patient.fullName}
      </Link>
    );
  };

  const productsTemplate = (repair: IRepairDao) => {
    return (
      <div className='flex flex-col space-y-3'>
        {repair.products.map((product) => (
          <p className='text-wrap body-xs' key={product.uid}>{`${product.description} ${
            product.serialNumber && `/ ${product.serialNumber}`
          }`}</p>
        ))}
      </div>
    );
  };

  const statusTemplate = (repair: IRepairDao) => <RepairStatusTag repair={repair} tableKey={tableKey} />;

  const actionsTemplate = (repair: IRepairDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.REPAIRS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey='patients.patient.repairs.table.action.add_note'
            onClick={() => dialog?.openDialog(<AddEditRepairNote repair={repair} />)}
          />
        </SharedElementPermissionGuard>
        <Link to={repair.uid}>
          <SharedButton labelKey='common.view' appearance='link' primaryOverride />
        </Link>
      </div>
    );
  };

  return (
    <>
      <SharedPageHeader title={t('navigation.repairs')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.REPAIRS}
            queryConstraints={[
              where('organisationUid', '==', userData.organisationUid),
              where('status', 'not-in', [RepairStatus.CANCELLED, RepairStatus.RETURNED_TO_PATIENT]),
            ]}
            queryOrder={['updated.at', 'desc']}
            tableConfig={{
              columns: [
                {
                  labelKey: 'repairs.repairs_list.table.header.patient',
                  key: 'patient',
                  contentTemplateId: 'patient',
                  width: 200,
                },
                {
                  labelKey: 'repairs.repairs_list.table.header.manufacturer',
                  key: 'manufacturer',
                  width: 150,
                },
                {
                  labelKey: 'repairs.repairs_list.table.header.products',
                  key: 'products',
                  contentTemplateId: 'products',
                  width: 270,
                },
                {
                  labelKey: 'repairs.repairs_list.table.header.status',
                  key: 'status',
                  contentTemplateId: 'status',
                  width: 230,
                },
                {
                  labelKey: 'repairs.repairs_list.table.header.last_updated',
                  key: 'updated',
                  contentTemplateId: 'lastUpdated',
                  width: 150,
                },
                {
                  key: 'actions',
                  contentTemplateId: 'actions',
                  width: 140,
                },
              ],
              contentTemplates: [
                {
                  id: 'patient',
                  template: patientTemplate,
                },
                {
                  id: 'products',
                  template: productsTemplate,
                },
                {
                  id: 'status',
                  template: statusTemplate,
                },
                {
                  id: 'actions',
                  template: actionsTemplate,
                },
              ],
            }}
            errorMessageKey='repairs.repairs_list.error'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default RepairsList;
