import { App, Form, FormInstance } from 'antd';
import { StockApiService } from 'core/api';
import { IOrderAccessoryOrServiceDao, IOrderDao, IOrderHearingAidDao } from 'core/api/types/order.interface';
import { IStockAccessoryDao, IStockHearingAidDao } from 'core/api/types/stock.interface';
import { Ear, EarData } from 'core/constants/ear';
import { StockStatus } from 'core/constants/stock-status';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { Timestamp } from 'firebase/firestore';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { buildAccessoryString, buildAidString } from 'shared/helpers/product-string-builder';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';

interface IGenerateStockDialogFormOutput {
  [key: `${string}.serialNumber`]: string;
  [key: `${string}.warranty`]: string;
  allocateToPatient: boolean;
}

interface IHearingAid {
  key: string;
  label: string;
  aidDetails: IOrderHearingAidDao | undefined;
}

const HearingAidForm = ({
  hearingAids,
  form,
  submitting,
}: {
  hearingAids: IHearingAid[];
  form: FormInstance;
  submitting: boolean;
}) => {
  const { t } = useTranslation();

  return hearingAids.map((aid: IHearingAid) => {
    if (!aid.aidDetails) return null;
    return (
      <div key={aid.key} className='px-4 pt-4 pb-2 border-b'>
        <p className='mb-3 font-semibold'>
          {t(aid.label)} <span className='font-light'>({buildAidString(aid.aidDetails)})</span>
        </p>
        <SharedForm
          formInstance={form}
          className=''
          fields={[
            {
              fieldKey: `${aid.key}.serialNumber`,
              control: ControlType.TextField,
              type: InputType.Text,
              label: t('patients.patient.orders.generate_stock_dialog.form.serial_number'),
              required: true,
            },
            {
              fieldKey: `${aid.key}.warranty`,
              control: ControlType.TextField,
              type: InputType.Text,
              label: t('patients.patient.orders.generate_stock_dialog.form.warranty'),
              required: true,
            },
          ]}
          submitting={submitting}
          name={`stock-aid-details-${aid.key}`}
          buttonsOverride={[]}
        />
      </div>
    );
  });
};

const AccessoryForm = ({
  accessories,
  form,
  submitting,
}: {
  accessories: IOrderAccessoryOrServiceDao[];
  form: FormInstance;
  submitting: boolean;
}) => {
  const { t } = useTranslation();

  return accessories.map((accessory) => (
    <div key={accessory.uid} className='px-4 pt-4 pb-2 border-b'>
      <p className='mb-3 font-semibold'>
        Accessory <span className='font-light'>({buildAccessoryString(accessory)})</span>
      </p>
      <SharedForm
        formInstance={form}
        className=''
        fields={[
          {
            fieldKey: `${accessory.uid}.serialNumber`,
            control: ControlType.TextField,
            type: InputType.Text,
            label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.serial_number'),
            required: true,
          },
          {
            fieldKey: `${accessory.uid}.warranty`,
            control: ControlType.TextField,
            type: InputType.Text,
            label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.warranty'),
            required: true,
          },
        ]}
        submitting={submitting}
        name={`fitting-receipt-accessory-details-${accessory.uid}`}
        buttonsOverride={[]}
      />
    </div>
  ));
};

const GenerateStockDialog = (order: IOrderDao) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const [form] = Form.useForm();
  const { userData } = useUserState();
  const { message } = App.useApp();
  const hearingAidState = useSelector(OrganisationSettingsSlice.selectHearingAids);

  const ACCESSORY_TYPE = 'accessory';
  const HEARING_AID_TYPE: 'hearingAid' = 'hearingAid';

  const findMatchingAid = (orderAid?: IOrderHearingAidDao) => {
    if (!orderAid) return undefined;

    return hearingAidState?.data?.find(
      (hearingAid) =>
        hearingAid.manufacturer === orderAid.manufacturer &&
        hearingAid.model === orderAid.model &&
        hearingAid.style === orderAid.style
    );
  };

  const hearingAids = useMemo(
    () => [
      {
        key: Ear.LEFT,
        label: EarData[Ear.LEFT].translationLabelKey,
        aidDetails: order.hearingAids.left,
      },
      {
        key: Ear.RIGHT,
        label: EarData[Ear.RIGHT].translationLabelKey,
        aidDetails: order.hearingAids.right,
      },
    ],
    [order.hearingAids]
  );

  const accessories = order.accessoriesAndServices.filter((accessory) => accessory.type === ACCESSORY_TYPE);

  const submit = async (data: IGenerateStockDialogFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      const organisationUid = userData.organisationUid;
      const userTimestamp = getActionTimestampFromUser(userData);
      const { allocateToPatient, ...stockDetails } = data;
      const accessoriesStock: IStockAccessoryDao[] = accessories.map((accessory) => ({
        uid: uuidv4(),
        organisationUid,
        created: userTimestamp,
        updated: userTimestamp,
        accessoryUid: accessory.itemKey,
        accessoryName: accessory.name,
        cost: accessory.price,
        manufacturer: accessory.manufacturer || '',
        serialNumber: stockDetails[`${accessory.uid}.serialNumber`],
        warranty: stockDetails[`${accessory.uid}.warranty`],
        type: ACCESSORY_TYPE,
        status: allocateToPatient ? StockStatus.SOLD : StockStatus.IN_STOCK,
        quantity: allocateToPatient ? 0 : 1,
        allocation: {
          allocations: allocateToPatient
            ? [
                {
                  at: Timestamp.fromDate(new Date()),
                  to: {
                    uid: order.patient.uid,
                    fullName: order.patient.fullName,
                  },
                  status: StockStatus.SOLD,
                },
              ]
            : [],
          patients: allocateToPatient ? [order.patient.uid] : [],
        },
      }));

      const hearingAidsStock: IStockHearingAidDao[] = hearingAids
        .map((aid) => {
          const aidDetails = aid.aidDetails;
          if (!aidDetails) return null;

          const matchingAid = findMatchingAid(aidDetails);
          if (!matchingAid) return null;

          const stockAid: IStockHearingAidDao = {
            uid: uuidv4(),
            organisationUid,
            created: userTimestamp,
            updated: userTimestamp,
            hearingAidUid: matchingAid.uid,
            manufacturer: aidDetails.manufacturer,
            model: aidDetails.model,
            style: aidDetails.style,
            power: aidDetails.power,
            colour: aidDetails.colour,
            cost: aidDetails.price,
            serialNumber: stockDetails[`${aid.key}.serialNumber`],
            warranty: stockDetails[`${aid.key}.warranty`],
            type: HEARING_AID_TYPE,
            status: allocateToPatient ? StockStatus.SOLD : StockStatus.IN_STOCK,
            allocated: {
              at: Timestamp.fromDate(new Date()),
              to: {
                uid: order.patient.uid,
                fullName: order.patient.fullName,
              },
              side: aid.key,
            },
          };

          return stockAid;
        })
        .filter((aid) => aid !== null);

      await StockApiService.bulkSet([...accessoriesStock, ...hearingAidsStock]);
      message.success(t('patients.patient.orders.generate_stock_dialog.success'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('patients.patient.orders.generate_stock_dialog.error'));
      setSubmitting(false);
      sentryCaptureException(error, 'Generating stock', userData);
    }
  };

  const customContent = () => {
    return (
      <div className='overflow-y-auto'>
        <HearingAidForm hearingAids={hearingAids} form={form} submitting={submitting} />
        <AccessoryForm accessories={accessories} form={form} submitting={submitting} />
        <SharedForm
          name='stock-allocation'
          formInstance={form}
          fields={[
            {
              fieldKey: 'allocateToPatient',
              control: ControlType.Switch,
              label: t('patients.patient.orders.generate_stock_dialog.form.allocate_to_patient'),
              required: true,
            },
          ]}
          existingValue={{
            allocateToPatient: false,
          }}
          onFinish={submit}
          submitting={submitting}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.patient.orders.generate_stock_dialog.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default GenerateStockDialog;
