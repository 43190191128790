import { App } from 'antd';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IRepairDao, IRepairNoteDao } from 'core/api/types/repairs.interface';
import { RepairsApiService } from 'core/api';
import { arrayUnion } from 'firebase/firestore';

interface IAddEditRepairNote {
  repair: IRepairDao;
  existingNote?: IRepairNoteDao;
}

interface IAddEditPatientNoteFormOutput {
  note: string;
}

const AddEditRepairNote = ({ repair, existingNote }: IAddEditRepairNote) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const { message } = App.useApp();

  const [submitting, setSubmitting] = useState(false);

  const fields: ISharedField[] = [
    {
      fieldKey: 'note',
      control: ControlType.TextArea,
      rows: 6,
      label: t('repairs.repair_detail.notes.add_edit_note.form.note'),
      required: true,
    },
  ];

  const submit = async (data: IAddEditPatientNoteFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      if (!existingNote) {
        await RepairsApiService.update(repair.uid, {
          notes: arrayUnion({
            uid: uuidv4(),
            content: data.note,
            created: userTimestamp,
            updated: userTimestamp,
          }),
          updated: userTimestamp,
        });
        message.success(t('repairs.repair_detail.notes.add_edit_note.create.success'));
      } else {
        await RepairsApiService.update(repair.uid, {
          notes: repair.notes?.map((note) =>
            note.uid === existingNote.uid
              ? {
                  ...note,
                  content: data.note,
                  updated: userTimestamp,
                }
              : note
          ),
          updated: userTimestamp,
        });
        message.success(t('repairs.repair_detail.notes.add_edit_note.edit.success'));
      }
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('repairs.repair_detail.notes.add_edit_note.error'));
      setSubmitting(false);
      sentryCaptureException(error, 'Create Repair Note', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditPatientNoteFormOutput>
        onFinish={submit}
        fields={fields}
        submitting={submitting}
        cancelButton={{
          onClick: () => dialog?.closeDialog(),
          appearance: 'text',
          labelKey: 'common.cancel',
        }}
        name='add-edit-repair-note-form'
        existingValue={existingNote ? { note: existingNote.content } : undefined}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(
        existingNote
          ? 'repairs.repair_detail.notes.add_edit_note.edit.title'
          : 'repairs.repair_detail.notes.add_edit_note.create.title'
      )}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditRepairNote;
