import { App } from 'antd';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IPatientDao } from 'core/api/types';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { CustomFormService } from 'core/api';
import { Timestamp } from 'firebase/firestore';
import { v4 as uuidv4 } from 'uuid';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import dayjs from 'dayjs';

interface ICreatePatientFormDialog {
  patient: IPatientDao;
}

interface ICreatePatientFormDialogFormOutput {
  template: string;
}

const CreatePatientFormDialog = ({ patient }: ICreatePatientFormDialog) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const { message } = App.useApp();
  const customFormTemplates = useSelector(OrganisationSettingsSlice.selectCustomFormTemplates);

  const submit = async (data: ICreatePatientFormDialogFormOutput) => {
    const customFormTemplate = customFormTemplates?.data?.find((form) => form.uid === data.template);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      if (!customFormTemplate) {
        throw new Error('No custom form selected');
      }

      const userTimestamp = getActionTimestampFromUser(userData);
      const formUid = uuidv4();
      await CustomFormService.set({
        templateUid: customFormTemplate.uid,
        organisationUid: userData.organisationUid,
        patientUid: patient.uid,
        completed: false,
        expires: Timestamp.fromDate(dayjs().add(1, 'week').toDate()),
        uid: formUid,
        created: userTimestamp,
        updated: userTimestamp,
      });
      const win = window.open(`${window.location.origin}/forms/${formUid}?mode=clinic`, '_blank');
      if (win) win.focus();
      message.success(t('patients.patient.forms.create.success'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('patients.patient.forms.create.error'));
      sentryCaptureException(error, 'Create/Edit Patient Form', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<ICreatePatientFormDialogFormOutput>
        onFinish={submit}
        fields={[
          {
            fieldKey: 'template',
            control: ControlType.Select,
            label: t('patients.patient.forms.create.form.template'),
            options:
              customFormTemplates?.data
                ?.filter((form) => !form.deleted)
                .map((form) => ({
                  value: form.uid,
                  label: form.name,
                })) ?? [],
            required: true,
          },
        ]}
        name='select-custom-form-template'
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.patient.forms.create.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default CreatePatientFormDialog;
