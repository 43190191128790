import { App, Dropdown, Tag } from 'antd';
import { StockApiService } from 'core/api';
import { IStockAccessoryDao, IStockDao } from 'core/api/types/stock.interface';
import { StockStatusData, StockStatusOptions, StockStatus } from 'core/constants/stock-status';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { arrayRemove, deleteField, increment, Timestamp } from 'firebase/firestore';
import { IUpdateDataPayload } from 'core/api/types';
import { ItemType } from 'antd/es/menu/interface';

interface ISharedStockStatusTableTemplate {
  stock: IStockDao;
  tableKey?: string;
  patientUid?: string;
  multiAccessoryAllocation?: boolean;
}

const SharedStockStatusTableTemplate = ({
  stock,
  tableKey,
  patientUid,
  multiAccessoryAllocation = false,
}: ISharedStockStatusTableTemplate) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const table = useAComponentPortal(tableKey ?? '');
  const [loading, setLoading] = useState(false);
  const { message } = App.useApp();

  let statusToUse: StockStatus = stock.status;
  if (stock.type === 'accessory' && patientUid) {
    const allocation = stock.allocation.allocations.find((allocation) => allocation.to.uid === patientUid);
    statusToUse = allocation?.status ?? stock.status;
  }
  const statusData = StockStatusData[statusToUse];
  const isAllocated =
    (stock.type === 'accessory' && patientUid) ||
    stock.status === StockStatus.SOLD ||
    stock.status === StockStatus.ON_TRIAL ||
    stock.status === StockStatus.RETURNED_TO_SUPPLIER;

  const getAllocatedAccessoryPayload = (key: StockStatus, stock: IStockAccessoryDao) => {
    if (key === StockStatus.IN_STOCK) {
      return {
        quantity: increment(1),
        ...(!multiAccessoryAllocation && { 'allocation.patients': arrayRemove(patientUid) }),
        'allocation.allocations': arrayRemove(stock.allocation.allocations.find((a) => a.to.uid === patientUid)),
      };
    } else {
      return {
        allocation: {
          ...stock.allocation,
          allocations: stock.allocation.allocations.map((a) =>
            a.to.uid === patientUid ? { ...a, status: key as StockStatus.ON_TRIAL | StockStatus.SOLD } : a
          ),
        },
      };
    }
  };

  const handleAllocatedAidUpdate = (key: StockStatus) => {
    if (key === StockStatus.IN_STOCK) {
      return {
        status: key,
        allocated: deleteField(),
      };
    } else {
      return {
        status: key,
      };
    }
  };

  const stockNotAddedYetStatuses = [StockStatus.NEEDS_ORDERING, StockStatus.ORDERED];

  const handleStatusChange = async (key: StockStatus) => {
    setLoading(true);
    let payload: IUpdateDataPayload<IStockDao> = {
      updated: getActionTimestampFromUser(userData),
      ...(stockNotAddedYetStatuses.includes(stock.status) &&
        !stockNotAddedYetStatuses.includes(key) && {
          stockAddedDate: Timestamp.now(),
        }),
      ...(stock.status === StockStatus.NEEDS_ORDERING &&
        key === StockStatus.ORDERED && {
          orderDate: Timestamp.now(),
        }),
    };
    try {
      if (isAllocated) {
        payload = {
          ...payload,
          ...(stock.type === 'accessory' ? getAllocatedAccessoryPayload(key, stock) : handleAllocatedAidUpdate(key)),
        };
      } else {
        payload = {
          ...payload,
          status: key,
        };
      }
      await StockApiService.update(stock.uid, payload);
      table?.exposedFunction();
      message.success(t('stock_management.table.status.update.success'));
    } catch (error) {
      message.error(t('stock_management.table.status.update.error'));
    } finally {
      setLoading(false);
    }
  };

  let items: ItemType[] = StockStatusOptions.filter(
    (status) =>
      status !== stock.status &&
      status !== StockStatus.ON_TRIAL &&
      status !== StockStatus.SOLD &&
      status !== StockStatus.RETURNED_TO_SUPPLIER
  ).map((status) => {
    const data = StockStatusData[status];
    return {
      key: status,
      label: t(data.translationLabelKey),
    };
  });

  if (stock.status === StockStatus.RETURNED_TO_SUPPLIER) {
    items = [
      {
        key: StockStatus.IN_STOCK,
        label: t('stock_management.table.status.back_to_stock'),
      },
    ];
  } else if (isAllocated) {
    items = [
      {
        key: StockStatus.IN_STOCK,
        label: t('stock_management.table.status.back_to_stock'),
      },
      {
        key: StockStatus.RETURNED_TO_SUPPLIER,
        label: t('stock_management.table.status.returned_to_supplier'),
      },
      ...(statusToUse === StockStatus.ON_TRIAL
        ? [
            {
              key: StockStatus.SOLD,
              label: t(StockStatusData[StockStatus.SOLD].translationLabelKey),
            },
          ]
        : [
            {
              key: StockStatus.ON_TRIAL,
              label: t(StockStatusData[StockStatus.ON_TRIAL].translationLabelKey),
            },
          ]),
    ];
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: async ({ key }) => handleStatusChange(key as StockStatus),
      }}
      trigger={['click']}
    >
      <Tag className='cursor-pointer' icon={loading && <SyncOutlined spin />} color={statusData?.color ?? 'default'}>
        {t(statusData ? statusData.translationLabelKey : 'common.unknown')}
      </Tag>
    </Dropdown>
  );
};
export default SharedStockStatusTableTemplate;
