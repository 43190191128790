import { useDialog } from 'core/providers/dialog-provider';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import SharedForm from 'shared/form/shared-form';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { App, Empty } from 'antd';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { IAddressInputOutput } from 'shared/interfaces/address.interface';
import dayjs, { Dayjs } from 'dayjs';
import { IPatientDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { deleteField, Timestamp } from 'firebase/firestore';
import { PatientApiService } from 'core/api';
import { Title, TitleData, TitleOptions } from 'core/constants/title';
import { Gender, GenderData, GenderOptions } from 'core/constants/gender';
import { MaritalStatus, MaritalStatusData, MaritalStatusOptions } from 'core/constants/marital-status';
import { ContactMethod, ContactMethodData, ContactMethodOptions } from 'core/constants/contact-method';
import { patientFormDefaultFields } from 'core/constants/organisation-forms';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { isNotNullOrEmpty } from 'shared/helpers/null-checkers';
import { isArray } from 'lodash';

interface IAddEditPatientFormOutput {
  [key: string]: string | IAddressInputOutput | Dayjs | string[] | undefined;
  referral: string;
  referralSubType?: string;
  title: Title;
  fullName: string;
  address?: IAddressInputOutput;
  phoneNumber: string;
  secondaryPhoneNumber?: string;
  emailAddress?: string;
  dob?: Dayjs;
  gender?: Gender;
  maritalStatus?: MaritalStatus;
  emergencyContactName?: string;
  emergencyContactNumber?: string;
  gpDetails?: string;
  conditions?: string[];
  contactPreference?: ContactMethod;
  contactPermissions?: ContactMethod[];
}

interface IAddEditPatientDialog {
  patient?: IPatientDao;
  navigateAfterSubmit?: boolean;
}

const AddEditPatientDialog = ({ patient, navigateAfterSubmit }: IAddEditPatientDialog) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const patientFormSettings = useSelector(OrganisationSettingsSlice.selectPatientFormSettings);
  const { message } = App.useApp();
  const leadTypeState = useSelector(OrganisationSettingsSlice.selectLeadTypes);
  const statuses = useSelector(OrganisationSettingsSlice.selectPatientWorkflowStatuses) ?? [];
  const navigate = useNavigate();
  const [form] = useForm();
  const [selectedReferral, setSelectedReferral] = useState<string | undefined>(patient?.referral);
  if (
    !leadTypeState ||
    leadTypeState.status !== 'success' ||
    !patientFormSettings ||
    !patientFormSettings.data ||
    patientFormSettings.status !== 'success'
  ) {
    dialog?.closeDialog();
    message.error(t('patients.add_edit_patient.patient_form_settings_error'));
  }
  const fieldSettings = patientFormSettings!.data!;
  const leadTypes = leadTypeState!.data;
  const referralSubTypeOptions = useMemo(
    () =>
      leadTypes
        .find((leadType) => leadType.uid === selectedReferral)
        ?.subTypes?.filter((s) => !s.deleted)
        .map((s) => ({
          label: s.name,
          value: s.uid,
        })),
    [leadTypes, selectedReferral]
  );

  const configurableDefaultFormFields: ISharedField[] = patientFormDefaultFields
    .filter((field) => !fieldSettings?.disabledFields.includes(field.key))
    .map((field) => {
      const base = {
        fieldKey: field.key,
        label: t(field.labelKey),
        required: field.locked,
      };

      switch (field.key) {
        case 'referral':
          return {
            ...base,
            control: ControlType.Select,
            options: leadTypes
              .filter((leadType) => !leadType.deleted)
              .map((leadType) => ({
                label: leadType.name,
                value: leadType.uid,
              })),
          };
        case 'referralSubType': {
          return {
            ...base,
            control: ControlType.Select,
            disabled: !selectedReferral || !referralSubTypeOptions,
            options: referralSubTypeOptions ?? [],
          };
        }
        case 'title':
          return {
            ...base,
            allowClear: true,
            control: ControlType.Select,
            options: TitleOptions.map((op) => {
              const option = TitleData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'address':
          return {
            ...base,
            control: ControlType.Address,
          };
        case 'phoneNumber':
        case 'secondaryPhoneNumber':
        case 'emergencyContactNumber':
          return { ...base, control: ControlType.TextField, type: InputType.Tel };
        case 'emailAddress':
          return { ...base, control: ControlType.TextField, type: InputType.Email };
        case 'dob':
          return { ...base, control: ControlType.DatePicker };
        case 'gender':
          return {
            ...base,
            control: ControlType.Select,
            options: GenderOptions.map((op) => {
              const option = GenderData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'maritalStatus':
          return {
            ...base,
            control: ControlType.Select,
            options: MaritalStatusOptions.map((op) => {
              const option = MaritalStatusData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'conditions': {
          let conditions: string[] = [];
          if (fieldSettings.defaultFields?.conditions) {
            conditions = isArray(fieldSettings.defaultFields?.conditions)
              ? fieldSettings.defaultFields.conditions
              : [fieldSettings.defaultFields.conditions];
          }
          return {
            ...base,
            control: ControlType.Select,
            mode: 'multiple',
            options: conditions.map((value) => ({
              label: value,
              value,
            })),
            notFoundContent: (
              <Empty
                className='my-2'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={t('patients.add_edit_patient.conditions_not_found')}
              />
            ),
          };
        }

        case 'contactPreference':
          return {
            ...base,
            control: ControlType.Select,
            options: ContactMethodOptions.map((op) => {
              const option = ContactMethodData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        case 'contactPermissions':
          return {
            ...base,
            control: ControlType.CheckboxGroup,
            options: ContactMethodOptions.map((op) => {
              const option = ContactMethodData[op];
              return {
                label: t(option.translationLabelKey),
                value: option.value,
              };
            }),
          };
        default:
          return { ...base, control: ControlType.TextField, type: InputType.Text };
      }
    });

  const customFormFields: ISharedField[] = fieldSettings?.customFields
    .filter((field) => !fieldSettings?.disabledFields.includes(field.key))
    .map((field) => ({
      fieldKey: field.key,
      control: ControlType.TextField,
      type: InputType.Text,
      label: field.label,
      required: false,
    }));

  const submit = async (data: IAddEditPatientFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      if (data.address?.manualInput && !data.address.detail) {
        message.error(t('patients.add_edit_patient.manual_address_not_saved'));
        return;
      }
      const actor = getActionTimestampFromUser(userData);
      const { referral, title, fullName, phoneNumber, ...rest } = data;
      const basePayload = {
        uid: uuidv4(),
        created: actor,
        organisationUid: userData.organisationUid,
        virtualServicingEnabled: false,
      };
      const payload = {
        referral,
        fullName,
        phoneNumber,
        updated: actor,
        ...(title && { title }),
        ...(rest.secondaryPhoneNumber && { secondaryPhoneNumber: rest.secondaryPhoneNumber }),
        ...(rest.emailAddress && { emailAddress: rest.emailAddress }),
        ...(rest.gender && { gender: rest.gender }),
        ...(rest.maritalStatus && { maritalStatus: rest.maritalStatus }),
        ...(rest.emergencyContactName && { emergencyContactName: rest.emergencyContactName }),
        ...(rest.emergencyContactNumber && { emergencyContactNumber: rest.emergencyContactNumber }),
        ...(rest.gpDetails && { gpDetails: rest.gpDetails }),
        ...(rest.contactPreference && { contactPreference: rest.contactPreference }),
        ...(rest.contactPermissions && { contactPermissions: rest.contactPermissions }),
        ...(fieldSettings.customFields && {
          customFields: fieldSettings.customFields
            .filter((field) => data[field.key] !== undefined)
            .map((field) => ({
              key: field.key,
              value: data[field.key] as string,
            })),
        }),
        ...(rest.conditions && { conditions: rest.conditions }),
      };

      !patient
        ? await PatientApiService.set({
            ...basePayload,
            ...payload,
            ...(rest.referralSubType && { referralSubType: rest.referralSubType }),
            ...(rest.address?.detail && { address: rest.address.detail }),
            ...(rest.dob && { dob: Timestamp.fromDate(rest.dob.toDate()) }),
            status: { status: statuses[0].key, updated: actor },
          })
        : await PatientApiService.update(patient.uid, {
            ...payload,
            title: title ?? deleteField(),
            referralSubType: rest.referralSubType ?? deleteField(),
            address: rest.address?.detail ?? deleteField(),
            dob: rest.dob ? Timestamp.fromDate(rest.dob.toDate()) : deleteField(),
            gpDetails: isNotNullOrEmpty(rest.gpDetails) ? rest.gpDetails : deleteField(),
            emailAddress: isNotNullOrEmpty(rest.emailAddress) ? rest.emailAddress : deleteField(),
            conditions: rest.conditions && rest.conditions?.length > 0 ? rest.conditions : deleteField(),
          });
      setSubmitting(false);
      dialog?.closeDialog();
      if (navigateAfterSubmit) {
        const uid = patient?.uid ?? basePayload.uid;
        navigate(`patients/${uid}`);
      }

      !patient
        ? message.success(t('patients.add_edit_patient.create.success'))
        : message.success(t('patients.add_edit_patient.edit.success'));
    } catch (error) {
      !patient
        ? message.error(t('patients.add_edit_patient.create.error'))
        : message.error(t('patients.add_edit_patient.edit.error'));
      sentryCaptureException(error, 'Add patient', userData);
    } finally {
      setSubmitting(false);
    }
  };

  const onFormChange = (change: Partial<IAddEditPatientFormOutput>) => {
    const [key, value] = Object.entries(change)[0];
    switch (key) {
      case 'referral':
        if (value === selectedReferral) return;
        setSelectedReferral(value as string);
        form.setFieldsValue({ referralSubType: undefined });
        break;
      default:
        break;
    }
  };

  const customContent = () => {
    const customFields = patient?.customFields?.reduce(
      (acc, field) => ({
        ...acc,
        [field.key]: field.value,
      }),
      {}
    );

    return (
      <SharedForm<IAddEditPatientFormOutput>
        formInstance={form}
        className='overflow-y-auto p-4'
        onFinish={submit}
        onChange={onFormChange}
        fields={[...configurableDefaultFormFields, ...customFormFields]}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add-edit-patient-form'
        existingValue={{
          ...patient,
          ...customFields,
          dob: patient?.dob && dayjs(patient.dob.toDate()),
          address: patient?.address
            ? {
                detail: patient.address,
                manualInput: false,
              }
            : undefined,
        }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.add_edit_patient.create.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditPatientDialog;
