import { IDaoBase } from 'core/api/types';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export const TextCellTemplate = (data: string) => {
  const { t } = useTranslation();
  return <p className='whitespace-pre-wrap body-sm break-words'>{data ?? t('common.na')}</p>;
};

export const LastUpdatedTemplate = (data: IDaoBase, format: string = 'DD/MM/YYYY, HH:mm') => {
  return <p className='whitespace-pre-wrap body-sm'>{dayjs(data.updated.at.toDate()).format(format)}</p>;
};

export const CreatedTemplate = <T extends IDaoBase>(data: T, format: string = 'DD/MM/YYYY, HH:mm') => {
  return <p className='whitespace-pre-wrap body-sm'>{dayjs(data.created.at.toDate()).format(format)}</p>;
};
