import { App } from 'antd';
import { RepairsApiService } from 'core/api';
import { IRepairDao } from 'core/api/types/repairs.interface';
import { Unsubscribe } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedPageHeader from 'shared/page-header/page-header';
import RepairStatusTag from './repair-status-tag';
import LastUpdated from 'shared/data-display/last-updated';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { useTheme } from 'core/providers/theme-provider';
import AddEditRepairDialog from './add-edit-repair-dialog';
import AddEditRepairNote from './add-edit-repair-note-dialog';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const RepairDetails = () => {
  const { t } = useTranslation();
  const { uid } = useParams();
  const [repair, setRepair] = useState<IRepairDao>();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const dialog = useDialog();
  const { primary } = useTheme();
  const navigate = useNavigate();
  const { userData } = useUserState();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (uid) {
      unsubscribe = RepairsApiService.onDocSnapshot(
        uid,
        (doc) => {
          if (doc.exists()) {
            setRepair(doc.data());
            setLoading(false);
          }
        },
        () => {
          setLoading(false);
          message.error(t('repairs.repair_detail.fetch_error'));
        }
      );
    }
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, t, uid]);

  const detailFields = [
    {
      key: 'fullName',
      label: t('repairs.add_edit_repair.form.patient'),
      value: (
        <Link style={{ color: primary.bg }} to={`/patients/${repair?.patient.uid}`}>
          {repair?.patient.fullName}
        </Link>
      ),
    },
    {
      key: 'products',
      label: t('repairs.add_edit_repair.form.products'),
      value: (
        <div>
          {repair?.products.map((product) => (
            <div className='w-fit text-wrap' key={product.uid}>{`${product.description} ${
              product.serialNumber && `/ ${product.serialNumber}`
            }`}</div>
          ))}
        </div>
      ),
    },
    {
      key: 'status',
      label: t('repairs.add_edit_repair.form.status'),
      value: <RepairStatusTag repair={repair} />,
    },
    {
      key: 'manufacturer',
      label: t('repairs.add_edit_repair.form.manufacturer'),
      value: repair?.manufacturer,
    },
    {
      key: 'accountNumber',
      label: t('repairs.add_edit_repair.form.account_number'),
      value: repair?.accountNumber,
    },
    {
      key: 'accountAddress',
      label: t('repairs.add_edit_repair.form.account_address'),
      value: repair?.accountAddress.formattedAddress,
    },
    {
      key: 'returnAddress',
      label: t('repairs.add_edit_repair.form.return_address'),
      value: repair?.returnAddress.formattedAddress,
    },
    {
      key: 'serviceOption',
      label: t('repairs.add_edit_repair.form.service_option'),
      value: repair?.serviceOption ?? t('common.not_provided'),
    },
    {
      key: 'reportedProblem',
      label: t('repairs.add_edit_repair.form.reported_problem'),
      value: <p className='whitespace-pre-wrap'>{repair?.reportedProblem}</p>,
    },
  ];

  const headerActions = [
    {
      element: (
        <div className='space-x-2'>
          <RegeneratePdfButton uid={uid!} />
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.REPAIRS_DELETE], [Permission.ORGANISATION_OWNER]]}
          >
            <SharedButton
              labelKey='repairs.repair_detail.header.delete_repair'
              onClick={() =>
                dialog?.openDialog(
                  <ConfirmActionDialog
                    action={async () => {
                      await RepairsApiService.permDelete(uid!);
                      navigate('/repairs');
                    }}
                    actionButtonProps={{
                      labelKey: 'common.delete',
                      danger: true,
                    }}
                    title={t('repairs.delete_repair.title')}
                    textContent={t('repairs.delete_repair.content')}
                    successMessage={t('repairs.delete_repair.success')}
                    errorMessage={t('repairs.delete_repair.error')}
                  />
                )
              }
              appearance='primary'
              danger
            />
          </SharedElementPermissionGuard>
        </div>
      ),
      key: 'deleteRepair',
    },
  ];

  return (
    <>
      <SharedPageHeader
        title={t('repairs.repair_detail.title')}
        showBack
        actions={loading ? undefined : headerActions}
      />
      <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
        {loading ? (
          <>
            <SkeletonElement width='100%' height='100%' />
            <SkeletonElement width='100%' height='100%' />
          </>
        ) : (
          <>
            <SharedCard
              outerClassName='h-fit'
              title={t('repairs.repair_detail.detail.title')}
              innerClassName='p-4'
              extra={
                <SharedElementPermissionGuard
                  requiredPermissions={[[Permission.REPAIRS_UPDATE], [Permission.ORGANISATION_OWNER]]}
                >
                  <SharedButton
                    labelKey='common.edit'
                    onClick={() =>
                      dialog?.openDialog(
                        <AddEditRepairDialog
                          patient={{
                            uid: repair?.patient.uid!,
                            fullName: repair?.patient.fullName!,
                          }}
                          existing={repair}
                        />
                      )
                    }
                    appearance='link'
                    primaryOverride
                  />
                </SharedElementPermissionGuard>
              }
            >
              {detailFields.map((field) => (
                <FormItemMimic
                  label={field.label}
                  key={field.key}
                  className='mb-4 last:mb-0'
                  labelClassName='text-gray-500'
                >
                  {field.value}
                </FormItemMimic>
              ))}
            </SharedCard>
            <SharedCard
              outerClassName='h-fit'
              title={t('repairs.repair_detail.notes.title')}
              extra={
                <SharedElementPermissionGuard
                  requiredPermissions={[[Permission.REPAIRS_UPDATE], [Permission.ORGANISATION_OWNER]]}
                >
                  <SharedButton
                    labelKey='patients.patient_note.add_note'
                    onClick={() => dialog?.openDialog(<AddEditRepairNote repair={repair!} />)}
                    appearance='link'
                    primaryOverride
                  />
                </SharedElementPermissionGuard>
              }
            >
              {repair?.notes
                ?.sort((a, b) => b.updated.at.seconds - a.updated.at.seconds)
                .map((note) => (
                  <div className='p-4 border-b last:border-b-0' key={note.uid}>
                    <p className='body-sm whitespace-pre-wrap'>{note.content}</p>
                    <div className='flex justify-between items-center mt-2'>
                      <LastUpdated updated={note.updated} />
                      <SharedElementPermissionGuard
                        requiredPermissions={[[Permission.REPAIRS_UPDATE], [Permission.ORGANISATION_OWNER]]}
                      >
                        <div className='flex space-x-3'>
                          <SharedButton
                            className='h-[10px] body-xs'
                            labelKey='common.edit'
                            onClick={() =>
                              dialog?.openDialog(<AddEditRepairNote repair={repair!} existingNote={note} />)
                            }
                            appearance='link'
                            primaryOverride
                          />
                          <SharedButton
                            className='h-[10px] body-xs'
                            labelKey='common.delete'
                            danger
                            onClick={() =>
                              dialog?.openDialog(
                                <ConfirmActionDialog
                                  action={() =>
                                    RepairsApiService.update(repair!.uid, {
                                      notes: repair!.notes?.filter((n) => n.uid !== note.uid),
                                      updated: getActionTimestampFromUser(userData),
                                    })
                                  }
                                  actionButtonProps={{
                                    labelKey: 'common.delete',
                                    danger: true,
                                  }}
                                  title={t('repairs.repair_detail.notes.delete.title')}
                                  textContent={t('repairs.repair_detail.notes.delete.content')}
                                  successMessage={t('repairs.repair_detail.notes.delete.success')}
                                  errorMessage={t('repairs.repair_detail.notes.delete.error')}
                                />
                              )
                            }
                            appearance='link'
                          />
                        </div>
                      </SharedElementPermissionGuard>
                    </div>
                  </div>
                ))}
              {repair?.notes?.length === 0 && (
                <p className='text-gray-400 p-4'>{t('repairs.repair_detail.notes.empty')}</p>
              )}
            </SharedCard>
          </>
        )}
      </div>
    </>
  );
};

export default RepairDetails;

const RegeneratePdfButton = ({ uid }: { uid: string }) => {
  const { t } = useTranslation();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(false);

  return (
    <SharedButton
      loading={loading}
      labelKey='repairs.repair_detail.header.regenerate_repair_form_pdf'
      onClick={async () => {
        try {
          setLoading(true);
          await RepairsApiService.regenerateRepairFormPdf(uid!);
          message.success(t('repairs.repair_detail.header.regenerate_repair_form_pdf.success'));
        } catch (err) {
          message.error(t('repairs.repair_detail.header.regenerate_repair_form_pdf.error'));
        } finally {
          setLoading(false);
        }
      }}
      appearance='default'
    />
  );
};
