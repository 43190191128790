import clsx from 'clsx';
import { IStockDao } from 'core/api/types/stock.interface';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const ConditionalTemplate = (value?: string | null, fallback: string = 'common.not_provided') => {
  const { t } = useTranslation();
  return <p className={clsx('body-sm', !value && 'text-gray-400')}>{value ?? t(fallback)}</p>;
};

export const LocationTemplate = (stock: IStockDao) => {
  return ConditionalTemplate(stock.location);
};

export const WarrantyTemplate = (stock: IStockDao) => {
  return ConditionalTemplate(stock.warranty);
};

export const SerialTemplate = (stock: IStockDao) => {
  return ConditionalTemplate(stock.serialNumber);
};

export const StockAddedTemplate = (data: IStockDao) => {
  const date = data.stockAddedDate ? dayjs(data.stockAddedDate.toDate()).format('DD/MM/YYYY') : undefined;
  return ConditionalTemplate(date, 'common.not_specified');
};
