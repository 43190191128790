import { App } from 'antd';
import { HearingTestsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IHearingTestDao } from 'core/api/types/hearing-test.interface';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { limit, orderBy, where } from 'firebase/firestore';
import AudiogramDiagram from 'modules/hearing-test/audiogram-diagram';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import AccessDenied from 'shared/permissions/denied';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientOverviewLastTestWidget = (patient: IPatientDao) => {
  const { t } = useTranslation();

  return (
    <SharedCard
      outerClassName='h-full flex flex-col'
      title={t('patients.patient.patient_overview.last_test_widget.title')}
      innerClassName='grow p-4 relative flex flex-col min-h-[600px]'
    >
      <SharedElementPermissionGuard
        requiredPermissions={[[Permission.PATIENT_HEARING_TESTS_READ], [Permission.ORGANISATION_OWNER]]}
        replacement={<AccessDenied />}
      >
        <Widget {...patient} />
      </SharedElementPermissionGuard>
    </SharedCard>
  );
};

const Widget = (patient: IPatientDao) => {
  const { userData } = useUserState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const [test, setTest] = useState<IHearingTestDao>();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = HearingTestsApiService.onCollectionSnapshot(
      (snap) => {
        if (snap.size > 0) {
          setTest(snap.docs[0].data());
          setLoading(false);
        } else {
          setLoading(false);
        }
      },
      (error) => {
        message.error(t('patients.patient.patient_overview.last_test_widget.error'));
        sentryCaptureException(error, 'Patient file fetching last test', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        limit(1),
        orderBy('testDate', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);
  return loading ? (
    <SkeletonElement height='100%' width='100%' />
  ) : (
    <>
      {test ? (
        <>
          <p className='text-gray-400'>
            {t('hearing_test.add_edit_hearing_test.form.test_date')}:{' '}
            {dayjs(test.testDate.toDate()).format('DD/MM/YYYY')}
          </p>
          <div className='grow flex'>
            <div className='h-full' />
            <AudiogramDiagram
              onValueChange={() => {}}
              audiogramValues={test.audiogram}
              height='100%'
              id={test.uid}
              viewOnly
            />
          </div>
        </>
      ) : (
        <div className='absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] flex flex-col items-center text-center'>
          <p className='text-gray-300'>{t('patients.patient.patient_overview.last_test_widget.no_test')}</p>
          <SharedElementPermissionGuard
            requiredPermissions={[[Permission.PATIENT_HEARING_TESTS_CREATE], [Permission.ORGANISATION_OWNER]]}
          >
            <Link to={`/hearing-test/create?patient=${patient.uid}`}>
              <SharedButton
                labelKey='patients.patient.patient_hearing_tests.create'
                appearance='link'
                primaryOverride
              />
            </Link>
          </SharedElementPermissionGuard>
        </div>
      )}
    </>
  );
};

export default PatientOverviewLastTestWidget;
