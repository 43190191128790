import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { App } from 'antd';
import { IRepairDao } from 'core/api/types/repairs.interface';
import FormItemMimic from 'shared/form/form-item-mimic';
import { Unsubscribe } from 'firebase/auth';
import { RepairsApiService, StockApiService } from 'core/api';
import { IStockAccessoryDao, IStockDao, IStockHearingAidDao } from 'core/api/types/stock.interface';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { and, deleteField, or, orderBy, where } from 'firebase/firestore';
import SharedButton from 'shared/button/button';
import { ManufacturerOptions } from 'core/constants/manufacturer';
import { useForm } from 'antd/es/form/Form';
import { v4 as uuid } from 'uuid';
import { PlusCircle } from 'react-feather';
import { IAddressInputOutput } from 'shared/interfaces/address.interface';
import { RepairStatus, RepairStatusData, RepairStatusOptions } from 'core/constants/repair-status';

interface IAddEditRepairFormOutput {
  manufacturer: string;
  accountNumber: string;
  accountAddress: IAddressInputOutput;
  returnAddress: IAddressInputOutput;
  serviceOption?: string;
  reportedProblem: string;
  selectionMode: 'select' | 'manual';
  products?: string[];
  [key: `serialNumber-${string}`]: string | undefined;
  [key: `description-${string}`]: string | undefined;
  status: RepairStatus;
}

interface IAddEditRepairDialog {
  existing?: IRepairDao;
  patient: {
    uid: string;
    fullName: string;
  };
  tableKey?: string;
}
const AddEditRepairDialog = ({ existing, tableKey, patient }: IAddEditRepairDialog) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const creating = !existing;
  const table = useAComponentPortal(tableKey ?? '');
  const [loading, setLoading] = useState(false);
  const [assignedProducts, setAssignedProducts] = useState<IStockDao[]>([]);
  const [productSelectionMode, setProductSelectionMode] = useState<'select' | 'manual'>(
    existing?.productSelectionMethod ?? 'select'
  );
  const [form] = useForm();
  const [manualProducts, setManualProducts] = useState<string[]>(creating ? [uuid()] : []);

  useEffect(() => {
    if (existing && existing.productSelectionMethod === 'manual') {
      setManualProducts(existing.products.map((product) => product.uid));
    }
  }, [existing]);

  const getProductOptionList = () => {
    const ops = assignedProducts
      .sort((a, b) => a.manufacturer.toLowerCase().localeCompare(b.manufacturer.toLowerCase()))
      .map((product) => {
        const values =
          product.type === 'hearingAid'
            ? [product.manufacturer, product.model, product.style, product.power, product.colour, product.serialNumber]
            : [product.manufacturer, product.accessoryName, product.serialNumber];
        return {
          label: values.filter((value) => Boolean(value)).join(' / '),
          value: product.uid,
        };
      });
    return ops;
  };

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => doc.data());
        setAssignedProducts(data);
        setLoading(false);
      },
      (error) => {
        message.error(t('repairs.add_edit_repair.fetching_products_error'));
        sentryCaptureException(error, 'Patient file fetching transactions', userData);
      },
      [],
      {
        filter: and(
          where('organisationUid', '==', userData?.organisationUid),
          or(
            and(where('type', '==', 'hearingAid'), where('allocated.to.uid', '==', patient.uid)),
            and(where('type', '==', 'accessory'), where('allocation.patients', 'array-contains', patient.uid))
          )
        ),
        orderBy: orderBy('updated.at', 'desc'),
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const buildAidString = (aid: IStockHearingAidDao) => {
    const { manufacturer, model, style, power, colour } = aid;
    return [manufacturer, model, style, power, colour].filter((value) => Boolean(value)).join(' / ');
  };

  const buildAccessoryString = (accessory: IStockAccessoryDao) => {
    const { manufacturer, accessoryName } = accessory;
    return [manufacturer, accessoryName].filter((value) => Boolean(value)).join(' / ');
  };

  const submit = async (data: IAddEditRepairFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        manufacturer: data.manufacturer,
        accountNumber: data.accountNumber,
        accountAddress: data.accountAddress.detail!,
        returnAddress: data.returnAddress.detail!,
        reportedProblem: data.reportedProblem,
        productSelectionMethod: data.selectionMode,
        products:
          data.selectionMode === 'select'
            ? (data.products ?? []).map((uid) => {
                const match = assignedProducts.find((product) => product.uid === uid)!;
                return {
                  uid: match.uid,
                  serialNumber: match.serialNumber ?? '',
                  description: match.type === 'hearingAid' ? buildAidString(match) : buildAccessoryString(match),
                };
              })
            : manualProducts.map((id) => ({
                uid: id,
                serialNumber: data[`serialNumber-${id}`] ?? '',
                description: data[`description-${id}`] ?? '',
              })),
        status: data.status,
        updated: userTimestamp,
        ...(data.serviceOption && { serviceOption: data.serviceOption }),
      };
      if (!creating) {
        await RepairsApiService.update(existing!.uid, {
          ...basePayload,
          serviceOption: data.serviceOption ? data.serviceOption : deleteField(),
        });
      } else {
        await RepairsApiService.set({
          uid: uuid(),
          organisationUid: userData?.organisationUid,
          patient,
          created: userTimestamp,
          notes: [],
          ...basePayload,
        });
      }
      message.success(t(existing ? 'repairs.add_edit_repair.edit.success' : 'repairs.add_edit_repair.create.success'));
      dialog?.closeDialog();
      table?.exposedFunction();
    } catch (err) {
      message.error(t(existing ? 'repairs.add_edit_repair.edit.error' : 'repairs.add_edit_repair.create.error'));
      setSubmitting(false);
    }
  };

  const manualProductButtons = (id: string, index: number, length: number) => {
    let showAdd = false;
    let showRemove = true;
    switch (index) {
      case 0:
        showAdd = length === 1;
        showRemove = false;
        break;
      case length - 1:
        showAdd = true;
        showRemove = true;
        break;
    }
    return (
      (showAdd || showRemove) && (
        <div className='flex space-x-4'>
          {showRemove && (
            <SharedButton
              onClick={() => setManualProducts((prev) => prev.filter((item) => item !== id))}
              appearance='link'
              danger
              labelKey='repairs.add_edit_repair.form.products.manual.remove'
              icon={<PlusCircle size={16} />}
            />
          )}
          {showAdd && (
            <SharedButton
              onClick={() => setManualProducts((prev) => [...prev, uuid()])}
              appearance='link'
              primaryOverride
              labelKey='repairs.add_edit_repair.form.products.manual.add'
              icon={<PlusCircle size={16} />}
            />
          )}
        </div>
      )
    );
  };

  const formFields: ISharedField[] = [
    {
      fieldKey: 'manufacturer',
      control: ControlType.Select,
      options: ManufacturerOptions.map((manufacturer) => ({
        label: manufacturer,
        value: manufacturer,
      })),
      required: true,
      label: t('repairs.add_edit_repair.form.manufacturer'),
    },
    {
      fieldKey: 'accountNumber',
      control: ControlType.TextField,
      type: InputType.Text,
      required: true,
      label: t('repairs.add_edit_repair.form.account_details'),
      placeholder: t('repairs.add_edit_repair.form.account_number'),
    },
    {
      fieldKey: 'accountAddress',
      control: ControlType.Address,
      required: true,
      placeholder: t('repairs.add_edit_repair.form.account_address'),
      showManualMode: false,
      extra: (
        <SharedButton
          labelKey='repairs.add_edit_repair.form.return_address.copy'
          onClick={() => form.setFieldValue('returnAddress', form.getFieldValue('accountAddress'))}
        />
      ),
    },
    {
      fieldKey: 'returnAddress',
      control: ControlType.Address,
      required: true,
      label: t('repairs.add_edit_repair.form.return_address'),
      showManualMode: false,
    },
    {
      fieldKey: 'serviceOption',
      control: ControlType.TextField,
      type: InputType.Text,
      required: false,
      label: t('repairs.add_edit_repair.form.service_option'),
    },
    {
      fieldKey: 'reportedProblem',
      control: ControlType.TextArea,
      rows: 4,
      required: true,
      label: t('repairs.add_edit_repair.form.reported_problem'),
    },
    {
      fieldKey: 'selectionMode',
      control: ControlType.RadioButton,
      options: [
        {
          label: t('repairs.add_edit_repair.form.selection_mode.select'),
          value: 'select',
        },
        {
          label: t('repairs.add_edit_repair.form.selection_mode.manual'),
          value: 'manual',
        },
      ],
      label: t('repairs.add_edit_repair.form.selection_mode'),
      required: true,
    },
    {
      fieldKey: 'products',
      control: ControlType.Select,
      options: getProductOptionList(),
      required: true,
      hidden: productSelectionMode !== 'select',
      mode: 'multiple',
    },
    ...manualProducts.flatMap<ISharedField>((id, index) => [
      {
        fieldKey: `serialNumber-${id}`,
        control: ControlType.TextField,
        type: InputType.Text,
        placeholder: t('repairs.add_edit_repair.form.product_serial_number'),
        required: true,
        hidden: productSelectionMode !== 'manual',
        label: t('repairs.add_edit_repair.form.manual_product', { index: index + 1 }),
      },
      {
        fieldKey: `description-${id}`,
        control: ControlType.TextField,
        type: InputType.Text,
        placeholder: t('repairs.add_edit_repair.form.product_description'),
        required: true,
        hidden: productSelectionMode !== 'manual',
        extra: manualProductButtons(id, index, manualProducts.length),
      },
    ]),
    {
      fieldKey: 'status',
      control: ControlType.Select,
      options: RepairStatusOptions.map((op) => {
        const match = RepairStatusData[op];
        return {
          label: t(match.translationLabelKey),
          value: op,
        };
      }),
      required: true,
      label: t('repairs.add_edit_repair.form.status'),
    },
  ];

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <FormItemMimic label={t('repairs.add_edit_repair.form.patient')}>
          <p className='font-semibold'>{patient.fullName}</p>
        </FormItemMimic>
        <SharedForm<IAddEditRepairFormOutput>
          formInstance={form}
          className=''
          initializing={loading}
          onFinish={submit}
          fields={formFields}
          submitting={submitting}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          name='repairs.add_edit_repair'
          existingValue={{
            selectionMode: productSelectionMode,
            ...(existing && {
              ...existing,
              accountAddress: {
                detail: existing.accountAddress,
                manualInput: false,
              },
              returnAddress: {
                detail: existing.accountAddress,
                manualInput: false,
              },
              ...(productSelectionMode === 'select'
                ? {
                    products: existing.products.map((product) => product.uid),
                  }
                : {
                    ...existing.products.reduce(
                      (
                        acc: {
                          [key: `serialNumber-${string}`]: string;
                          [key: `description-${string}`]: string;
                        },
                        product
                      ) => {
                        acc[`serialNumber-${product.uid}`] = product.serialNumber;
                        acc[`description-${product.uid}`] = product.description;
                        return acc;
                      },
                      {}
                    ),
                    products: undefined,
                  }),
            }),
          }}
          onChange={(change) => {
            const [key, value] = Object.entries(change)[0];
            if (key === 'selectionMode') {
              setProductSelectionMode(value as 'select' | 'manual');
            }
          }}
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'repairs.add_edit_repair.create.title' : 'repairs.add_edit_repair.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditRepairDialog;
