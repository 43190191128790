import { App, Form } from 'antd';
import { PatientDocumentsApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IPatientDao } from 'core/api/types';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useWatch } from 'antd/es/form/Form';
import { ref, uploadString } from 'firebase/storage';
import { storage } from 'core/config/firebase';

interface IAddPatientDocumentDialog {
  patient: IPatientDao;
}

interface IAddPatientDocumentFormOutput {
  fileCapture: File[];
  type: string;
}

const AddPatientDocumentDialog = ({ patient }: IAddPatientDocumentDialog) => {
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const dialog = useDialog();
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const [uploadDataUrl, setUploadDataUrl] = useState<string | ArrayBuffer | null>();
  const reader = useMemo(() => {
    return new FileReader();
  }, []);

  const uploadTypes = useSelector(OrganisationSettingsSlice.selectUploadTypes);

  const [submitting, setSubmitting] = useState(false);

  const uploadedFiles = useWatch('fileCapture', form);

  useEffect(() => {
    if (uploadedFiles) {
      if (uploadedFiles.length === 0) {
        setUploadDataUrl(undefined);
      } else {
        reader.onloadend = () => {
          setUploadDataUrl(reader.result);
        };
        reader.readAsDataURL(uploadedFiles[0]);
      }
    }
  }, [uploadedFiles, reader]);

  const AddPatientDocumentDialogFields: ISharedField[] = [
    {
      fieldKey: 'fileCapture',
      control: ControlType.File,
      label: 'File',
      required: true,
      maxCount: 1,
      maxFileSize: organisationData?.settings?.maxPatientUploadSize ?? 3000000,
    },
    {
      fieldKey: 'type',
      control: ControlType.Select,
      label: 'Type',
      options:
        uploadTypes?.data?.map((type) => ({
          value: type.uid,
          label: type.name,
        })) ?? [],
      required: true,
    },
  ];

  const submit = async (data: IAddPatientDocumentFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const { fileCapture, type } = data;

      const uid = uuidv4();

      const basePayload = {
        path: `patientDocuments/${userData.organisationUid}/${patient.uid}/${type}/${uid}_${fileCapture[0].name}`,
        type,
        name: fileCapture[0].name,
        updated: userTimestamp,
      };

      await uploadString(ref(storage, basePayload.path), uploadDataUrl!.toString(), 'data_url');

      await PatientDocumentsApiService.set({
        uid: uuidv4(),
        created: userTimestamp,
        patientUid: patient.uid,
        organisationUid: userData.organisationUid,
        ...basePayload,
      });

      message.success(t('dialog.add_patient_document.success.description'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('dialog.add_patient_document.error.description'));
      setSubmitting(false);
      sentryCaptureException(error, 'Create Patient Document', userData);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddPatientDocumentFormOutput>
        formInstance={form}
        onFinish={submit}
        fields={AddPatientDocumentDialogFields}
        submitting={submitting}
        cancelButton={{
          onClick: () => dialog?.closeDialog(),
          appearance: 'text',
          labelKey: 'common.cancel',
        }}
        name='add-patient-document-form'
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('dialog.add_patient_document.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddPatientDocumentDialog;
