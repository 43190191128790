import { Permission } from 'core/constants/permission';
import { useTranslation } from 'react-i18next';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { InputNumber, Select, Switch, TimePicker } from 'antd';
import { ReactNode } from 'react';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import SetTextInputDialog from 'shared/dialog/text-input-dialog';
import { Timestamp } from 'firebase/firestore';
import dayjs from 'dayjs';
import SetCustomOptionsDialog from 'shared/dialog/custom-options-dialog';

export type IFieldSettingValue = boolean | string | number | string[] | Timestamp | null;

interface IFieldSetting {
  setValue: (key: string, value: IFieldSettingValue) => Promise<void>;
  setDisabled?: (key: string, value: boolean) => void;
  fieldKey: string;
  labelKey: string;
  permissions?: Permission[];
  fieldValue?: IFieldSettingValue;
  selectOptions?: { value: string; label: string }[];
  isDisabled?: boolean;
  isLoading?: boolean;
  isLocked?: boolean;
  customOptions?: boolean;
  mode?: 'multiple' | 'tags';
  statusIcon?: ReactNode;
  setDisabledTime?: (hour: number) => {
    disabledHours: () => number[];
    disabledMinutes: () => number[];
  };
  mandatoryToggle?: {
    value?: boolean;
    show: boolean;
    onChange?: (key: string, value: boolean) => void;
  };
}

const FieldSetting = ({
  setValue,
  setDisabled,
  fieldKey,
  labelKey,
  permissions = [],
  fieldValue,
  selectOptions,
  isDisabled,
  isLoading,
  isLocked,
  customOptions,
  mode,
  statusIcon,
  setDisabledTime,
  mandatoryToggle,
}: IFieldSetting) => {
  const { t } = useTranslation();
  const dialog = useDialog();

  const format = 'HH:mm';

  return (
    <div className='border-b p-4 flex justify-between last:border-b-0' key={fieldKey}>
      <div className='flex items-center space-x-2'>
        {statusIcon}
        <p>{t(labelKey)}</p>
      </div>
      <div className='flex items-center'>
        <SharedElementPermissionGuard requiredPermissions={[[Permission.ORGANISATION_OWNER], permissions]}>
          {!isDisabled && mandatoryToggle?.show && (
            <div className='mr-4 flex space-x-1.5 items-center'>
              <p>{t('form_settings.custom_forms.add_edit_form.components.add_edit_component.required')}</p>
              <Switch
                defaultChecked={mandatoryToggle.value ?? true}
                onChange={(checked) => mandatoryToggle.onChange?.(fieldKey, checked)}
              />
            </div>
          )}
          {(!isDisabled && fieldValue instanceof Timestamp && (
            <TimePicker
              value={dayjs(fieldValue.toDate())}
              format={format}
              minuteStep={5}
              showNow={false}
              onChange={(value) => setValue(fieldKey, value ? Timestamp.fromDate(value.toDate()) : null)}
              disabledTime={setDisabledTime ? (value) => setDisabledTime(value.hour()) : undefined}
              allowClear={false}
            />
          )) ||
            (!isDisabled && selectOptions && (
              <Select
                className='min-w-[200px]'
                defaultValue={fieldValue}
                options={selectOptions}
                mode={mode}
                onChange={(value) => setValue(fieldKey, value)}
              />
            )) ||
            (!isDisabled && customOptions && (
              <SharedButton
                appearance='link'
                primaryOverride
                labelKey={'form_settings.patient_form.conditions.edit_options'}
                onClick={() => {
                  dialog?.openDialog(
                    <SetCustomOptionsDialog
                      title={t(labelKey)}
                      fieldKey={fieldKey}
                      defaultValue={Array.isArray(fieldValue) ? fieldValue : []}
                      onFinish={(value) => {
                        setValue(fieldKey, value);
                        dialog?.closeDialog();
                      }}
                    />
                  );
                }}
                loading={isLoading}
              />
            )) ||
            (!isDisabled && typeof fieldValue === 'string' && (
              <SharedButton
                appearance='link'
                primaryOverride
                labelKey={'common.edit'}
                onClick={() =>
                  dialog?.openDialog(
                    <SetTextInputDialog
                      title={t(labelKey)}
                      fieldKey={fieldKey}
                      defaultValue={fieldValue}
                      onFinish={(value) => {
                        setValue(fieldKey, value);
                        dialog?.closeDialog();
                      }}
                    />
                  )
                }
                loading={isLoading}
              />
            )) ||
            (!isDisabled && typeof fieldValue === 'number' && (
              <InputNumber<number>
                disabled={isDisabled}
                defaultValue={fieldValue}
                min={0}
                max={100}
                formatter={(value) => `${value}%`}
                parser={(value) => value?.replace('%', '') as unknown as number}
                onChange={(value) => setValue(fieldKey, value ?? 0)}
              />
            )) ||
            (!isDisabled && typeof fieldValue === 'boolean' && (
              <Switch defaultChecked={fieldValue} onChange={(checked) => setValue(fieldKey, checked)} />
            ))}

          {!isLocked && (
            <Switch
              className='ml-4'
              defaultChecked={!isDisabled}
              onChange={(checked) => (setDisabled ? setDisabled(fieldKey, checked) : null)}
            />
          )}
        </SharedElementPermissionGuard>
      </div>
    </div>
  );
};

export default FieldSetting;
