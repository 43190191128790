import { Tag } from 'antd';
import { PatientAlertsApiService } from 'core/api';
import { PatientAlertType, PatientAlertTypeData, PatientAlertTypeOptions } from 'core/constants/patient-alert-type';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { where } from 'firebase/firestore';
import {
  IDomainPatientAlert,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import PatientAlertAcknowledgementButton from 'modules/patients/patient/alerts/patient-alert-acknowledgement-button';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SharedCard from 'shared/card/card';
import SharedFilter, { IFilter } from 'shared/filter/filter';
import { StringIndexedStringArrayValue } from 'shared/helpers/interface.helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import SharedTable from 'shared/table/table';

interface IUpcomingAlerts {
  [PatientAlertType.REVIEW]: number;
  [PatientAlertType.GENERAL_FOLLOW_UP]: number;
  [PatientAlertType.RE_TEST]: number;
  [PatientAlertType.PHONE_CALL]: number;
}

const AlertsList = () => {
  const { t } = useTranslation();
  const tableKey = 'patient_alerts.list.table';
  const { primary } = useTheme();
  const alertsState = useSelector(OrganisationSettingsSlice.selectPatientAlerts);
  const alerts = [...(alertsState?.data ?? [])];
  const [activeFilters, setActiveFilters] = useState<StringIndexedStringArrayValue>({});
  const [upcomingAlerts, setUpcomingAlerts] = useState<IUpcomingAlerts>({
    [PatientAlertType.REVIEW]: 0,
    [PatientAlertType.GENERAL_FOLLOW_UP]: 0,
    [PatientAlertType.RE_TEST]: 0,
    [PatientAlertType.PHONE_CALL]: 0,
  });
  const { userData } = useUserState();
  const [countsLoading, setCountsLoading] = useState(true);

  useEffect(() => {
    const getPreviewCounts = async () => {
      setCountsLoading(true);
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        where('acknowledged', '==', false),
        where('alertDateTime', '<=', dayjs().add(6, 'months').endOf('day').toDate()),
        where('alertDateTime', '>=', dayjs().endOf('day').toDate()),
      ];
      try {
        const [reviewSnap, followUpSnap, reTestSnap] = await Promise.all([
          PatientAlertsApiService.getCollectionCount([
            ...baseConstraints,
            where('type', '==', PatientAlertType.REVIEW),
          ]),
          PatientAlertsApiService.getCollectionCount([
            ...baseConstraints,
            where('type', '==', PatientAlertType.GENERAL_FOLLOW_UP),
          ]),
          PatientAlertsApiService.getCollectionCount([
            ...baseConstraints,
            where('type', '==', PatientAlertType.RE_TEST),
          ]),
        ]);
        setUpcomingAlerts({
          [PatientAlertType.REVIEW]: reviewSnap.data().count,
          [PatientAlertType.GENERAL_FOLLOW_UP]: followUpSnap.data().count,
          [PatientAlertType.RE_TEST]: reTestSnap.data().count,
          [PatientAlertType.PHONE_CALL]: 0,
        });
        setCountsLoading(false);
      } catch (error) {
        sentryCaptureException(error, 'Getting upcoming alerts', userData);
      }
    };
    getPreviewCounts();
  }, [userData]);

  const patientTemplate = (alert: IDomainPatientAlert) => {
    const firstLine = alert.patient.address?.formattedAddress.split(',')[0];
    const postCode = alert.patient.address?.postcode;
    return (
      <div>
        <Link to={`/patients/${alert.patient.uid}/alerts`} style={{ color: primary.bg }}>
          {alert.patient.fullName}
        </Link>
        <p className='body-xs text-gray-500 text-wrap'>
          {firstLine && postCode ? `${firstLine}, ${postCode}` : t('patients.patient.information.address_not_defined')}
        </p>
      </div>
    );
  };

  const typeTemplate = (alert: IDomainPatientAlert) => {
    const match = PatientAlertTypeData[alert.type];
    return <p className='body-sm'>{t(match?.translationLabelKey ?? 'common.unknown')}</p>;
  };

  const statusTemplate = (alert: IDomainPatientAlert) => {
    return (
      <Tag color={alert.acknowledged ? 'green' : 'red'}>
        {t(alert.acknowledged ? 'common.acknowledged' : 'common.unacknowledged')}
      </Tag>
    );
  };

  const actionsTemplate = (alert: IDomainPatientAlert) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <PatientAlertAcknowledgementButton alert={alert} tableKey={tableKey} />
      </div>
    );
  };

  const lastUpdatedTemplate = (alert: IDomainPatientAlert) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {t('common.at_by', {
          at: dayjs.unix(alert.updatedAtSeconds).format('DD/MM/YYYY, HH:mm'),
          by: alert.updatedBy.fullName,
        })}
      </p>
    );
  };

  const createdTemplate = (alert: IDomainPatientAlert) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {t('common.at_by', {
          at: dayjs.unix(alert.createdAtSeconds).format('DD/MM/YYYY, HH:mm'),
          by: alert.createdBy.fullName,
        })}
      </p>
    );
  };

  const filters: IFilter[] = [
    {
      key: 'type',
      label: t('patient_alerts.list.table.header.type'),
      options: PatientAlertTypeOptions.map((type) => {
        const match = PatientAlertTypeData[type];
        return { value: type, label: t(match.translationLabelKey) };
      }),
      mode: 'multiple',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.patient.alerts')} />
      <SharedCard title={t('patient_alerts.list.preview.title')} innerClassName='grid grid-cols-2 md:grid-cols-4'>
        {PatientAlertTypeOptions.map((type) => (
          <div key={type} className='p-4 border-r last:border-r-0'>
            <p className='text-gray-500 mb-1'>{t(PatientAlertTypeData[type].translationLabelKey)}</p>
            {countsLoading ? (
              <SkeletonElement height='60px' width='70px' />
            ) : (
              <p className='text-4xl font-extralight'>{upcomingAlerts[type]}</p>
            )}
          </div>
        ))}
      </SharedCard>
      <SharedFilter filters={filters} onFilterChange={(filters) => setActiveFilters(filters)} />
      <SharedCard title={t('patient_alerts.list.table.title')}>
        <SharedTable
          headerBackgroundColor='#f8fafc'
          loading={alertsState?.status !== 'success'}
          rows={alerts
            .filter((alert) => {
              if (activeFilters.type) {
                return activeFilters.type.includes(alert.type);
              }
              return true;
            })
            .sort((a, b) => a.alertDateTimeSeconds - b.alertDateTimeSeconds)
            .map((alert) => ({ key: alert.uid, data: alert }))}
          contentTemplates={[
            {
              id: 'patient',
              template: patientTemplate,
            },
            {
              template: typeTemplate,
              id: 'type',
            },
            {
              template: (alert: IDomainPatientAlert) => (
                <p className='body-sm'>{dayjs.unix(alert.alertDateTimeSeconds).format('DD/MM/YYYY')}</p>
              ),
              id: 'alertDateTime',
            },
            {
              template: actionsTemplate,
              id: 'actions',
            },
            {
              template: statusTemplate,
              id: 'status',
            },
            {
              template: lastUpdatedTemplate,
              id: 'lastUpdatedAt',
            },
            {
              template: createdTemplate,
              id: 'createdAt',
            },
          ]}
          columns={[
            {
              labelKey: 'patient_alerts.list.table.header.patient',
              key: 'patient',
              contentTemplateId: 'patient',
              width: 250,
            },
            {
              labelKey: 'patient_alerts.list.table.header.type',
              key: 'type',
              contentTemplateId: 'type',
            },
            {
              labelKey: 'patient_alerts.list.table.header.alert_date_time',
              key: 'alertDateTime',
              contentTemplateId: 'alertDateTime',
            },
            {
              labelKey: 'patient_alerts.list.table.header.status',
              key: 'status',
              contentTemplateId: 'status',
            },
            {
              labelKey: 'patient_alerts.list.table.header.created',
              key: 'created',
              contentTemplateId: 'createdAt',
              width: 160,
            },
            {
              labelKey: 'patient_alerts.list.table.header.updated',
              key: 'lastUpdated',
              contentTemplateId: 'lastUpdatedAt',
              width: 160,
            },
            {
              key: 'actions',
              contentTemplateId: 'actions',
              width: 120,
            },
          ]}
        />
      </SharedCard>
    </>
  );
};

export default AlertsList;
