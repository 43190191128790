export enum RepairStatus {
  CREATED = 'created',
  AWAITING_POSTAGE = 'awaitingPostage',
  SENT_TO_MANUFACTURER = 'sentToManufacturer',
  DISPATCHED_BY_MANUFACTURER = 'dispatchedByManufacturer',
  RECEIVED = 'received',
  RETURNED_TO_PATIENT = 'returnedToPatient',
  CANCELLED = 'cancelled',
  ON_HOLD = 'onHold',
}

export const RepairStatusData = {
  [RepairStatus.CREATED]: {
    translationLabelKey: 'repair_status.created',
    color: 'blue',
    value: RepairStatus.CREATED,
  },
  [RepairStatus.AWAITING_POSTAGE]: {
    translationLabelKey: 'repair_status.awaiting_postage',
    color: 'orange',
    value: RepairStatus.AWAITING_POSTAGE,
  },
  [RepairStatus.SENT_TO_MANUFACTURER]: {
    translationLabelKey: 'repair_status.sent_to_manufacturer',
    color: 'orange',
    value: RepairStatus.SENT_TO_MANUFACTURER,
  },
  [RepairStatus.DISPATCHED_BY_MANUFACTURER]: {
    translationLabelKey: 'repair_status.dispatched_by_manufacturer',
    color: 'orange',
    value: RepairStatus.DISPATCHED_BY_MANUFACTURER,
  },
  [RepairStatus.RECEIVED]: {
    translationLabelKey: 'repair_status.received',
    color: 'orange',
    value: RepairStatus.RECEIVED,
  },
  [RepairStatus.RETURNED_TO_PATIENT]: {
    translationLabelKey: 'repair_status.returned_to_patient',
    color: 'orange',
    value: RepairStatus.RETURNED_TO_PATIENT,
  },
  [RepairStatus.CANCELLED]: {
    translationLabelKey: 'repair_status.cancelled',
    color: 'red',
    value: RepairStatus.CANCELLED,
  },
  [RepairStatus.ON_HOLD]: {
    translationLabelKey: 'repair_status.on_hold',
    color: 'red',
    value: RepairStatus.ON_HOLD,
  },
};

export const RepairStatusOptions = [
  RepairStatus.CREATED,
  RepairStatus.AWAITING_POSTAGE,
  RepairStatus.SENT_TO_MANUFACTURER,
  RepairStatus.DISPATCHED_BY_MANUFACTURER,
  RepairStatus.RECEIVED,
  RepairStatus.RETURNED_TO_PATIENT,
  RepairStatus.CANCELLED,
  RepairStatus.ON_HOLD,
];
