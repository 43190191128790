export enum Manufacturer {
  OTICON = 'Oticon',
  PHONAK = 'Phonak',
  RESOUND = 'ReSound',
  SIGNIA = 'Signia',
  STARKEY = 'Starkey',
  WIDEX = 'Widex',
  UNITRON = 'Unitron',
  BERNAFON = 'Bernafon',
  AUDIBEL = 'Audibel',
}

export const ManufacturerOptions = [
  Manufacturer.OTICON,
  Manufacturer.PHONAK,
  Manufacturer.RESOUND,
  Manufacturer.SIGNIA,
  Manufacturer.STARKEY,
  Manufacturer.WIDEX,
  Manufacturer.UNITRON,
  Manufacturer.BERNAFON,
  Manufacturer.AUDIBEL,
];
