import { IPatientDao } from 'core/api/types';
import { Permission } from 'core/constants/permission';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { useUserState } from 'core/providers/user-provider';
import { useDialog } from 'core/providers/dialog-provider';
import { IPatientNoteDao } from 'core/api/types/patient-notes.interface';
import { PatientNotesApiService } from 'core/api';
import { FirestoreError, QuerySnapshot, where } from 'firebase/firestore';
import AddEditPatientNoteDialog from './add-edit-patient-note-dialog';
import { App } from 'antd';
import { Unsubscribe } from 'firebase/auth';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import LastUpdated from 'shared/data-display/last-updated';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SkeletonElement from 'shared/skeleton/skeleton-element';

const PatientNotes = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { message } = App.useApp();
  const [loading, setLoading] = useState(true);
  const [patientNotes, setPatientNotes] = useState<IPatientNoteDao[]>([]);
  const { userData } = useUserState();

  const handleSnapshot = useCallback((snap: QuerySnapshot<IPatientNoteDao>) => {
    const notes = snap.docs.map((doc) => doc.data()) ?? [];
    setPatientNotes(notes.sort((a, b) => b.updated.at.seconds - a.updated.at.seconds));
    setLoading(false);
  }, []);

  const handleSubscriptionError = useCallback(
    (error: FirestoreError) => {
      message.error(t('patients.patient.patient_notes.error.description'));
      sentryCaptureException(error, 'Fetching Patient Notes', userData);
      setLoading(false);
    },
    [message, t, userData]
  );

  useEffect(() => {
    if (!userData?.organisationUid) {
      throw new Error(t('auth.user.error'));
    }

    setLoading(true);
    let unsubscribe: Unsubscribe;
    unsubscribe = PatientNotesApiService.onCollectionSnapshot(handleSnapshot, handleSubscriptionError, [
      where('patientUid', '==', patient.uid),
      where('organisationUid', '==', userData?.organisationUid),
    ]);
    return () => {
      unsubscribe();
    };
  }, [handleSnapshot, handleSubscriptionError, userData?.organisationUid, patient.uid, t]);

  return (
    <SharedCard
      title={t('patients.patient.patient_notes')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PATIENT_NOTES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditPatientNoteDialog patient={patient} />)}
            type='button'
            appearance='link'
            labelKey='patients.patient_note.add_note'
            primaryOverride
          />
        </SharedElementPermissionGuard>
      }
    >
      {loading ? (
        <div className='p-4'>
          <SkeletonElement width='100%' height='40px' />
          <SkeletonElement width='250px' height='10px' className='mt-1' />
        </div>
      ) : (
        <>
          {patientNotes.length === 0 && <p className='text-gray-400 p-4'>{t('common.no_results')}</p>}

          {patientNotes.map((note) => (
            <div key={note.uid} className='p-4 border-b last:border-b-0'>
              <div className='flex items-center justify-between'>
                <div>
                  {note.isHtml ? (
                    <div className='body-sm' dangerouslySetInnerHTML={{ __html: note.note }} />
                  ) : (
                    <p className='body-sm whitespace-pre-wrap'>{note.note}</p>
                  )}
                  <LastUpdated updated={note.updated} outerClassName='mt-2' />
                </div>
                <div className='flex justify-end space-x-4'>
                  {userData?.uid === note.created.by.uid && (
                    <SharedElementPermissionGuard
                      requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENT_NOTES_UPDATE]]}
                    >
                      <SharedButton
                        labelKey='common.edit'
                        appearance='link'
                        primaryOverride
                        onClick={() =>
                          dialog?.openDialog(<AddEditPatientNoteDialog patient={patient} patientNote={note} />)
                        }
                      />
                    </SharedElementPermissionGuard>
                  )}
                  <SharedElementPermissionGuard
                    requiredPermissions={[[Permission.ORGANISATION_OWNER], [Permission.PATIENT_NOTES_DELETE]]}
                  >
                    <SharedButton
                      labelKey='common.delete'
                      danger
                      appearance='link'
                      onClick={() =>
                        dialog?.openDialog(
                          <ConfirmActionDialog
                            action={() => PatientNotesApiService.permDelete(note.uid)}
                            actionButtonProps={{
                              labelKey: 'common.delete',
                              danger: true,
                            }}
                            title={t('dialog.delete_patient_note.title')}
                            textContent={t('dialog.delete_patient_note.content')}
                            successMessage={t('dialog.delete_patient_note.success.description')}
                            errorMessage={t('dialog.delete_patient_note.error.description')}
                          />
                        )
                      }
                    />
                  </SharedElementPermissionGuard>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </SharedCard>
  );
};

export default PatientNotes;
