import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IDomainCalendarResource, OrganisationSettingsSlice } from '../organisation-settings-slice';
import { HolderOutlined } from '@ant-design/icons';
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, useDroppable, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface ICalendarOrder {
  value?: string[];
  onChange: (value: string[]) => void;
}

const CalendarOrder = ({ value = [], onChange }: ICalendarOrder) => {
  const { t } = useTranslation();
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const calendarResources = calendarResourcesState?.data || [];
  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
  const { setNodeRef } = useDroppable({ id: 'order' });
  const sortedResources = [...calendarResources].sort(
    (a, b) => value.findIndex((uid) => a.uid === uid) - value.findIndex((uid) => b.uid === uid)
  );

  const onDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) {
      return;
    }
    const activeIndex = sortedResources.findIndex((resource) => resource.uid === active.id);
    const overIndex = sortedResources.findIndex((resource) => resource.uid === over.id);
    const updated = arrayMove(sortedResources, activeIndex, overIndex);
    onChange(updated.map((resource) => resource.uid));
  };

  return (
    <DndContext onDragEnd={onDragEnd} modifiers={[restrictToVerticalAxis]} sensors={sensors}>
      <div className='p-4 flex justify-between items-center'>
        <p>{t('calendar.order')}</p>
        <div ref={setNodeRef}>
          <SortableContext items={sortedResources.map((r) => r.uid)}>
            {sortedResources.map((resource, index) => (
              <CalendarOrderResource resource={resource} index={index} key={resource.uid} />
            ))}
          </SortableContext>
        </div>
      </div>
    </DndContext>
  );
};

export default CalendarOrder;

interface ICalendarOrderResource {
  resource: IDomainCalendarResource;
  index: number;
}

const CalendarOrderResource = ({ resource, index }: ICalendarOrderResource) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: resource.uid,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={style}
      key={resource.uid}
      {...listeners}
      className='flex items-center space-x-2'
    >
      <HolderOutlined />
      <p>{`${index + 1}. ${resource.fullName}`}</p>
    </div>
  );
};
