import { IOrderAccessoryOrServiceDao, IOrderHearingAidDao } from 'core/api/types/order.interface';

export const buildAidString = (aid: IOrderHearingAidDao) => {
  const { manufacturer, model, style, receiver, gain, earbudSize, earbudType, power, colour } = aid;
  return [manufacturer, model, style, receiver, gain, earbudSize, earbudType, power, colour]
    .filter((value) => Boolean(value))
    .join(' / ');
};

export const buildAccessoryString = (accessory: IOrderAccessoryOrServiceDao) => {
  const { manufacturer, name } = accessory;
  return [manufacturer, name].filter((value) => Boolean(value)).join(' / ');
};
