import { Alert, App } from 'antd';
import { useForm } from 'antd/es/form/Form';
import clsx from 'clsx';
import { AppointmentsApiService, HolidayAndUnavailabilityApiService, PatientApiService } from 'core/api';
import { IPatientDao, IUserDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import {
  AppointmentLocation,
  AppointmentLocationData,
  AppointmentLocationOptions,
} from 'core/constants/appointment-location';
import { ControlType } from 'core/enums/control-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { and, deleteField, FirestoreError, or, orderBy, where } from 'firebase/firestore';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCalendar from 'shared/calendar/calendar';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import {
  getAppointment60MinuteTimeSlots,
  getCalendarHours,
  getTimestampFromDateAndTimeString,
} from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import { v4 as uuidv4 } from 'uuid';
import * as turf from '@turf/turf';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { useDialog } from 'core/providers/dialog-provider';
import TimelinePreviewDialog from 'shared/dialog/timeline-preview-dialog';
import ProgressBar from 'shared/progress-bar/progress-bar';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';

interface IAddEditAppointmentFormOutput {
  type: string;
  clinic: string;
  location: AppointmentLocation;
  assignee: string;
  date: Dayjs;
  startTime: Dayjs;
  endTime: Dayjs;
  additionalNote?: string;
  sendConfirmation?: boolean;
  referral?: string;
  referralSubType?: string;
}

const AddEditAppointment = () => {
  // Hooks
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const patientUid = searchParams.get('patient');
  const appointmentUid = searchParams.get('appointment');
  const assigneeParam = searchParams.get('assignee');
  const clinicParam = searchParams.get('clinic');
  const dateParam = searchParams.get('date');
  const timeParam = searchParams.get('time');
  const dialog = useDialog();
  const { message } = App.useApp();
  const { userData, organisationData } = useUserState();
  const navigate = useNavigate();
  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const calendarResources = calendarResourcesState?.data;
  const leadTypeState = useSelector(OrganisationSettingsSlice.selectLeadTypes);
  const selectedDay = useMemo(() => (dateParam ? dayjs(dateParam, 'YYYY-MM-DD') : dayjs().startOf('day')), [dateParam]);

  // Form
  const [form] = useForm();
  const [{ type: selectedType, clinic, location, assignee, date, startTime, endTime, referral }, setWatchedFormValues] =
    useState<Partial<IAddEditAppointmentFormOutput>>({
      date: selectedDay,
      startTime:
        dateParam && timeParam
          ? selectedDay.hour(parseInt(timeParam.split(':')[0])).minute(parseInt(timeParam.split(':')[1]))
          : undefined,
      assignee: assigneeParam ?? undefined,
    });

  // State
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedAssigneeData, setSelectedAssigneeData] = useState<IDomainCalendarResource>();
  const [recommendedResources, setRecommendedResources] = useState<IDomainCalendarResource[]>([]);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const [patientData, setPatientData] = useState<IPatientDao>();
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const [appointment, setAppointment] = useState<IAppointmentDao>();
  const [loadingCalendarAppointments, setLoadingCalendarAppointments] = useState(true);
  const [unavailability, setUnavailability] = useState<IHolidayAndUnavailabilityDao[]>([]);
  const [loadingCalendarUnavailability, setLoadingCalendarUnavailability] = useState(true);
  const [mobileShowCalendar, setMobileShowCalendar] = useState(false);
  const [showSendConfirmationSwitch, setShowSendConfirmationSwitch] = useState(true);
  const [startDate, setStartDate] = useState<Date>();

  // Variables
  const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
  const startHour = calendarStart.hour();
  const startMinute = calendarStart.minute();
  const calendarEnd = dayjs(organisationData?.calendar.endTime.toDate());
  const endHour = calendarEnd.hour();
  const endMinute = calendarEnd.minute();
  const calendarHours = getCalendarHours(startHour, endHour);
  const assigneeList = useMemo(() => {
    if (selectedType && clinic && calendarResources) {
      return calendarResources.filter(
        (resource) => resource.assignableAppointmentTypes.includes(selectedType) && resource.clinics.includes(clinic)
      );
    }
    return undefined;
  }, [calendarResources, clinic, selectedType]);
  const referralSubTypeOptions = useMemo(
    () =>
      leadTypeState?.data
        .find((leadType) => leadType.uid === referral)
        ?.subTypes?.filter((s) => !s.deleted)
        .map((s) => ({
          label: s.name,
          value: s.uid,
        })),
    [leadTypeState?.data, referral]
  );

  useMemo(() => {
    if (date) {
      const newStartDate = dayjs(date).startOf('week').startOf('day').toDate();
      if (!startDate || (startDate && newStartDate.getTime() !== startDate.getTime())) {
        setStartDate(newStartDate);
      }
    }
  }, [date, startDate]);

  useEffect(() => {
    if (assignee && assigneeList) {
      const assigneeMatch = assigneeList.find((resource) => resource.uid === assignee);
      if (!assigneeMatch) {
        form.setFieldsValue({ assignee: undefined });
        setWatchedFormValues((prev) => ({ ...prev, assignee: undefined }));
      }
    }
  }, [assignee, assigneeList, form]);

  useEffect(() => {
    const fetchPatientData = async (uid: string) => {
      try {
        const snap = await PatientApiService.get(uid);
        if (snap.exists()) {
          setPatientData(snap.data());
        } else {
          message.error(t('calendar.add_edit_appointment.patient_not_found'));
          sentryCaptureException(
            new Error(`Tried to fetch patient: ${uid} but they don't exist`),
            'Fetching patient data for appointment'
          );
          navigate(-1);
        }
      } catch (error) {
        message.error(t('calendar.add_edit_appointment.fetch_patient_error'));
        sentryCaptureException(error, 'Fetching patient data for appointment');
        navigate(-1);
      }
    };

    if (!patientUid || patientUid === 'undefined') {
      navigate(-1);
      message.info(t('calendar.add_edit_appointment.patient_not_selected'));
    } else {
      fetchPatientData(patientUid);
    }
  }, [patientUid, message, navigate, t]);

  useEffect(() => {
    const fetchAppointmentData = async () => {
      setLoading(true);
      try {
        const snap = await AppointmentsApiService.get(appointmentUid!);
        if (snap.exists()) {
          const data = snap.data();
          setAppointment(data);
          setWatchedFormValues({
            type: data.type,
            clinic: data.clinic,
            location: data.location,
            assignee: data.assignee.uid,
            date: dayjs(data.startDateTime.toDate()),
            startTime: dayjs(data.startDateTime.toDate()),
            endTime: dayjs(data.endDateTime.toDate()),
            referral: data.referral,
          });
        } else {
          message.error(t('calendar.add_edit_appointment.appointment_not_found'));
          sentryCaptureException(
            new Error(`Tried to fetch appointment: ${appointmentUid} but it doesn't exist`),
            'Fetching appointment data for editing appointment',
            userData
          );
        }
      } catch (error) {
        message.error(t('calendar.add_edit_appointment.fetch_appointment_error'));
        sentryCaptureException(error, 'Fetching appointment data for editing appointment');
      } finally {
        setLoading(false);
      }
    };

    if (appointmentUid) {
      fetchAppointmentData();
    } else {
      setLoading(false);
    }
  }, [appointmentUid, message, userData, t]);

  const getRecommendedResources = useCallback(async (resources: IDomainCalendarResource[], address?: IAddressDao) => {
    if (!address || !address.lat || !address.lng) {
      setRecommendedResources([]);
    } else {
      const recommended = resources.filter((resource) => {
        if (!resource.workingAreas) {
          return false;
        }
        const polys = resource.workingAreas
          .map((areaString) =>
            areaString.split('##').map((point) => {
              const { lat, lng } = JSON.parse(point) as google.maps.LatLngLiteral;
              return [lng, lat];
            })
          )
          .map((positions) => {
            const first = positions[0];
            return turf.polygon([[...positions, first]]);
          });

        return polys.some((poly) => turf.booleanPointInPolygon([address.lng!, address.lat!], poly));
      });
      setRecommendedResources(recommended);
    }
  }, []);

  useEffect(() => {
    if (clinic && location) {
      let address = patientData?.address;
      if (location === AppointmentLocation.CLINIC) {
        const clinicData = clinicsState?.data.find((clinicData) => clinicData.uid === clinic);
        address = clinicData?.address;
      }
      getRecommendedResources(assigneeList ?? [], address);
    }
  }, [assigneeList, clinic, clinicsState?.data, getRecommendedResources, location, patientData?.address]);

  const handleSubscriptionError = useCallback(
    (error: FirestoreError, userData?: IUserDao) => {
      message.error(t('appointments.appointments_calendar.get_appointments_error'));
      sentryCaptureException(error, 'Appointments calendar fetching appointments', userData);
    },
    [message, t]
  );

  useEffect(() => {
    const subscriptions: Unsubscribe[] = [];
    let unsubscribe: Unsubscribe | undefined;
    if (assigneeList && assigneeList.length > 0 && startDate) {
      setLoadingCalendarAppointments(true);
      setLoadingCalendarUnavailability(true);
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        where(
          'assignee.uid',
          'in',
          assigneeList.map((resource) => resource.uid)
        ),
      ];
      subscriptions.push(
        AppointmentsApiService.onCollectionSnapshot(
          (snap) => {
            setAppointments(snap.docs.map((doc) => doc.data()));
            setLoadingCalendarAppointments(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [
            ...baseConstraints,
            where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
            where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
          ]
        ),
        HolidayAndUnavailabilityApiService.onCollectionSnapshot(
          (snap) => {
            setUnavailability(snap.docs.map((doc) => doc.data()));
            setLoadingCalendarUnavailability(false);
          },
          (error) => handleSubscriptionError(error, userData),
          [],
          {
            filter: and(
              ...baseConstraints,
              or(
                and(
                  where('repeat.isRepeating', '==', false),
                  or(
                    and(
                      where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    ),
                    and(
                      where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
                      where('endDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
                    )
                  )
                ),
                and(
                  where('repeat.isRepeating', '==', true),
                  where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
                  where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate())
                )
              )
            ),
            orderBy: orderBy('startDateTime'),
          }
        )
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, t, userData, startDate, assigneeList, handleSubscriptionError]);

  useEffect(() => {
    if (assignee) {
      setSelectedAssigneeData(calendarResources?.find((resource) => resource.uid === assignee));
    }
  }, [assignee, calendarResources]);

  const checkTimeIsWithinCalendarHours = (time: Dayjs) => {
    const d = date ?? selectedDay;
    const selectedTime = getTimestampFromDateAndTimeString(d, time.format('HH:mm'));
    const start = getTimestampFromDateAndTimeString(d, dayjs().hour(startHour).minute(startMinute).format('HH:mm'));
    const end = getTimestampFromDateAndTimeString(d, dayjs().hour(endHour).minute(endMinute).format('HH:mm'));
    return selectedTime >= start && selectedTime <= end;
  };

  useEffect(() => {
    const appointmentTypeFullDetail = appointmentTypeState?.data.find((type) => type.uid === selectedType);
    const confirmationsEnabled =
      appointmentTypeFullDetail?.confirmation.sms.enabled ||
      appointmentTypeFullDetail?.confirmation.email.enabled ||
      false;
    setShowSendConfirmationSwitch(confirmationsEnabled);
    form.setFieldValue('sendConfirmation', confirmationsEnabled);
  }, [appointmentTypeState?.data, form, selectedType]);

  const formFields: ISharedField[] = [
    {
      fieldKey: 'type',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.appointment_type'),
      options:
        appointmentTypeState?.data
          .filter((type) => !type.deleted)
          .map((type) => ({ label: type.name, value: type.uid })) ?? [],
      required: true,
    },
    {
      fieldKey: 'clinic',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.clinic'),
      options:
        clinicsState?.data
          ?.filter((clinic) => !clinic.deleted)
          .map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? [],
      required: true,
    },
    {
      fieldKey: 'location',
      control: ControlType.RadioButton,
      label: t('calendar.add_edit_appointment.form.location'),
      options: AppointmentLocationOptions.map((location) => {
        const option = AppointmentLocationData[location];
        return {
          label: t(option.translationLabelKey),
          value: option.value,
        };
      }),
      required: true,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'assignee',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.assignee'),
      options: assigneeList?.map((resource) => ({ label: resource.fullName, value: resource.uid })) ?? [],
      required: true,
      hidden: !selectedType || !clinic,
      extra: recommendedResources.length > 0 && (
        <div className='border border-blue-400 bg-blue-50 rounded-md px-4 py-2 body-xs text-blue-950'>
          <p className='font-bold mb-1'>Recommended assignee</p>
          {recommendedResources.map((res) => (
            <p key={res.uid}>• {res.fullName}</p>
          ))}
        </div>
      ),
    },
    {
      fieldKey: 'date',
      control: ControlType.DatePicker,
      label: t('calendar.add_edit_appointment.form.date'),
      disabledDate: (current: Dayjs) =>
        !(organisationData?.calendar.enabledDays ?? [0, 1, 2, 3, 4, 5, 6]).includes(current.day()),
      required: true,
      hidden: !selectedType || !clinic,
      fullWidth: true,
    },
    {
      fieldKey: 'startTime',
      control: ControlType.TimePicker,
      label: t('calendar.add_edit_appointment.form.start_time'),
      minuteStep: 5,
      required: true,
      hidden: !selectedType || !clinic,
      allowClear: true,
      fullWidth: true,
      disabledTime: () => ({
        disabledHours: () => calendarHours.disabled,
      }),
      dependencies: ['endTime'],
      customRules: [
        {
          validator: async (_, value: Dayjs) => {
            if (value) {
              if (endTime && value.isAfter(endTime, 'minute')) {
                return Promise.reject(t('calendar.add_edit_appointment.form.start_time_after_end'));
              }

              if (!checkTimeIsWithinCalendarHours(value)) {
                return Promise.reject(t('calendar.add_edit_appointment.form.time_outside_working_hours'));
              }
            }
          },
        },
      ],
    },
    {
      fieldKey: 'endTime',
      control: ControlType.TimePicker,
      disabledTime: () => ({
        disabledHours: () => calendarHours.disabled,
      }),
      minuteStep: 5,
      label: t('calendar.add_edit_appointment.form.end_time'),
      required: true,
      hidden: !selectedType || !clinic,
      allowClear: true,
      fullWidth: true,
      dependencies: ['startTime'],
      customRules: [
        {
          validator: async (_, value: Dayjs) => {
            if (value) {
              if (startTime && value.isBefore(startTime, 'minute')) {
                return Promise.reject(t('calendar.add_edit_appointment.form.end_time_before_start'));
              }

              if (!checkTimeIsWithinCalendarHours(value)) {
                return Promise.reject(t('calendar.add_edit_appointment.form.time_outside_working_hours'));
              }
            }
          },
        },
      ],
    },
    {
      fieldKey: 'additionalNote',
      control: ControlType.TextArea,
      label: t('calendar.add_edit_appointment.form.additional_information'),
      rows: 3,
      required: false,
      hidden: !selectedType || !clinic,
    },
    {
      fieldKey: 'referral',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.referral'),
      options:
        leadTypeState?.data
          .filter((leadType) => !leadType.deleted)
          .map((leadType) => ({
            label: leadType.name,
            value: leadType.uid,
          })) ?? [],
      required: false,
      hidden: !selectedType || !clinic,
      allowClear: true,
    },
    {
      fieldKey: 'referralSubType',
      control: ControlType.Select,
      label: t('calendar.add_edit_appointment.form.referral_sub_type'),
      options: referralSubTypeOptions ?? [],
      required: false,
      hidden: !referral || !referralSubTypeOptions || referralSubTypeOptions.length === 0,
      allowClear: true,
    },
    {
      fieldKey: 'sendConfirmation',
      control: ControlType.Switch,
      label: t('calendar.add_edit_appointment.form.send_confirmation'),
      required: true,
      hidden: !selectedType || !clinic || !showSendConfirmationSwitch,
    },
  ];

  const saveAppointment = async (data: IAddEditAppointmentFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);

      const createPayload = {
        uid: uuidv4(),
        organisationUid: userData?.organisationUid,
        created: userTimestamp,
      };

      const payload = {
        updated: userTimestamp,
        type: data.type,
        clinic: data.clinic,
        location: data.location,
        assignee: {
          fullName: selectedAssigneeData?.fullName ?? '',
          uid: data.assignee,
        },
        startDateTime: getTimestampFromDateAndTimeString(data.date, data.startTime.format('HH:mm')),
        endDateTime: getTimestampFromDateAndTimeString(data.date, data.endTime.format('HH:mm')),
        patient: patientData!,
        cancelled: false,
        outcomeHistory: [],
        confirmed: false,
        ...(data.additionalNote && { additionalNote: data.additionalNote }),
        ...(data.referral && { referral: data.referral }),
        ...(data.referralSubType && { referralSubType: data.referralSubType }),
        sendConfirmation: data.sendConfirmation ?? false,
      };

      appointmentUid
        ? await AppointmentsApiService.update(appointmentUid, {
            ...payload,
            referral: data.referral ? data.referral : deleteField(),
            referralSubType: data.referralSubType ? data.referralSubType : deleteField(),
          })
        : await AppointmentsApiService.set({
            ...createPayload,
            ...payload,
          });
      message.success(
        t(
          appointmentUid ? 'calendar.add_edit_appointment.edit.success' : 'calendar.add_edit_appointment.create.success'
        )
      );
      navigate(-1);
    } catch (error) {
      message.error(
        t(appointmentUid ? 'calendar.add_edit_appointment.edit.error' : 'calendar.add_edit_appointment.create.error')
      );
      sentryCaptureException(error, appointmentUid ? 'Editing appointment' : 'Creating new appointment', userData);
      setSubmitting(false);
    }
  };

  const submit = async (data: IAddEditAppointmentFormOutput) => {
    const override = selectedAssigneeData?.workingLocationOverrides?.[dayjs().format('YYYY-MM-DD')];
    const todaysLocation = override || selectedAssigneeData?.workingLocations?.[data.date.day()];

    if (
      !todaysLocation ||
      (todaysLocation === 'domiciliary' && data.location === 'home') ||
      todaysLocation === data.clinic
    ) {
      saveAppointment(data);
    } else {
      dialog?.openDialog(
        <ConfirmActionDialog
          action={() => saveAppointment(data)}
          actionButtonProps={{
            labelKey: 'common.confirm',
          }}
          title={t(
            todaysLocation === 'nonWorking'
              ? 'calendar.add_edit_appointment.form.confirm-non-working.title'
              : 'calendar.add_edit_appointment.form.confirm-location-difference.title'
          )}
          textContent={t(
            todaysLocation === 'nonWorking'
              ? 'calendar.add_edit_appointment.form.confirm-non-working.content'
              : 'calendar.add_edit_appointment.form.confirm-location-difference.content'
          )}
        />
      );
    }
  };

  const handleChanges = (
    change: Partial<IAddEditAppointmentFormOutput>,
    all: Partial<IAddEditAppointmentFormOutput>
  ) => {
    const newWatchedValues = { ...all };
    const [key, value] = Object.entries(change)[0];
    if (key !== 'endTime') {
      const start = (() => {
        if (key === 'startTime') {
          return value as Dayjs;
        }
        return newWatchedValues.startTime ?? startTime ?? undefined;
      })();
      const end = newWatchedValues.endTime;
      if (start && !end) {
        const appointmentTypeFullDetail = appointmentTypeState?.data.find((type) => type.uid === selectedType);
        let newEndTime = start.add(appointmentTypeFullDetail?.duration ?? 15, 'minute');
        const calendarEndDateTime = date?.hour(endHour).minute(endMinute);
        if (newEndTime.isAfter(calendarEndDateTime, 'minute')) {
          newEndTime = calendarEnd;
        }
        form.setFieldValue('endTime', newEndTime);
        newWatchedValues.endTime = newEndTime;
        form.validateFields(['endTime']);
      }
    }
    setWatchedFormValues((prevState) => ({ ...prevState, ...newWatchedValues }));
  };

  return !loading ? (
    <>
      <SharedPageHeader
        title={t(
          appointmentUid ? 'calendar.add_edit_appointment.edit.title' : 'calendar.add_edit_appointment.create.title'
        )}
        showBack
      />
      <div className='rounded-md bg-white shadow-md mb-4 grow flex flex-col md:flex-row overflow-hidden'>
        <div
          className={clsx(
            'w-full grow md:basis-[300px] flex flex-col justify-between md:grow-0 md:shrink-0 md:border-r overflow-y-auto',
            mobileShowCalendar && 'hidden md:block'
          )}
        >
          <SharedForm<IAddEditAppointmentFormOutput>
            onChange={handleChanges}
            formInstance={form}
            onFinish={submit}
            fields={formFields}
            submitting={submitting}
            name='add-edit-appointment-form'
            existingValue={{
              ...appointment,
              assignee: appointment?.assignee.uid ?? assigneeParam ?? undefined,
              startTime: (() => {
                if (appointment) {
                  return dayjs(appointment.startDateTime.toDate());
                }
                if (timeParam) {
                  return selectedDay.hour(parseInt(timeParam.split(':')[0])).minute(parseInt(timeParam.split(':')[1]));
                }
                return undefined;
              })(),
              endTime: appointment ? dayjs(appointment.endDateTime.toDate()) : undefined,
              date: appointment ? dayjs(appointment.startDateTime.toDate()) : selectedDay,
              clinic: appointment?.clinic ?? clinicParam ?? undefined,
            }}
          />
          {selectedType && date && clinic && assignee && startTime && endTime && location && selectedAssigneeData && (
            <div className='px-4 py-2 border-t'>
              <SharedButton
                labelKey='calendar.add_edit_appointment.preview_route'
                onClick={() => {
                  const newAppointmentAddress =
                    location === AppointmentLocation.HOME
                      ? patientData?.address
                      : clinicsState?.data.find((c) => c.uid === clinic)?.address;
                  if (newAppointmentAddress) {
                    dialog?.openDialog(
                      <TimelinePreviewDialog
                        appointments={appointments}
                        resource={selectedAssigneeData}
                        newAppointment={{
                          isNew: true,
                          uid: appointmentUid ?? 'new',
                          patientName: patientData!.fullName,
                          appointmentAddress: newAppointmentAddress,
                          startDateTime: getTimestampFromDateAndTimeString(date, startTime.format('HH:mm')),
                          endDateTime: getTimestampFromDateAndTimeString(date, endTime.format('HH:mm')),
                          clinic,
                          location,
                        }}
                      />
                    );
                  } else {
                    message.error(t('calendar.add_edit_appointment.timeline_preview.no_address'));
                  }
                }}
                appearance='link'
                fullWidth
              />
            </div>
          )}
        </div>

        <div
          className={clsx('grow flex overflow-hidden', !mobileShowCalendar && 'hidden md:flex')}
          ref={calendarWrapperRef}
        >
          {!selectedType || !date || !clinic ? (
            <div className='p-4 w-full grow'>
              <Alert
                className='self-start'
                message={t('calendar.add_edit_appointment.select_initial_fields')}
                type='info'
                showIcon
              />
            </div>
          ) : (
            <SharedCalendar
              filters={{}}
              calendarWrapperRef={calendarWrapperRef}
              startHour={startHour}
              loading={loadingCalendarAppointments || loadingCalendarUnavailability}
              timeSlots={getAppointment60MinuteTimeSlots(startHour, endHour)}
              appointments={appointments}
              unavailability={unavailability}
              people={assigneeList ?? []}
              currentDate={date}
              changeDate={(newDate) => {
                form.setFieldValue('date', dayjs(newDate));
                setWatchedFormValues((prev) => ({ ...prev, date: dayjs(newDate) }));
              }}
              newAppointment={
                date && startTime && endTime && startTime < endTime
                  ? { date, start: startTime, end: endTime }
                  : undefined
              }
              highlightedPerson={assignee}
              highlightedClinic={clinic}
              minDate={selectedDay}
              showAppointmentMenu={false}
            />
          )}
        </div>

        <div className='p-4 border-t md:hidden'>
          <SharedButton
            onClick={() => setMobileShowCalendar(!mobileShowCalendar)}
            fullWidth
            labelKey={
              mobileShowCalendar
                ? 'calendar.add_edit_appointment.hide_calendar'
                : 'calendar.add_edit_appointment.show_calendar'
            }
          />
        </div>
      </div>
    </>
  ) : (
    <>
      <ProgressBar />
      <div className='flex py-4 h-full'>
        <SkeletonElement width='30%' height='100%' />
        <SkeletonElement width='70%' height='100%' className='ml-4' />
      </div>
    </>
  );
};

export default AddEditAppointment;
