import { App, Tag } from 'antd';
import { PatientAlertsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IPatientAlertDao } from 'core/api/types/patient-alert.interface';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import AddEditPatientAlertDialog from './add-edit-patient-alert-dialog';
import { PatientAlertTypeData } from 'core/constants/patient-alert-type';
import dayjs from 'dayjs';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import PatientAlertAcknowledgementButton from './patient-alert-acknowledgement-button';

const PatientAlertsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState<IPatientAlertDao[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (userData) {
      setLoading(true);
      unsubscribe = PatientAlertsApiService.onCollectionSnapshot(
        (snap) => {
          setAlerts(snap.docs.map((doc) => doc.data()));
          setLoading(false);
        },
        (error) => {
          message.error(t('patients.patient.alerts.error'));
          sentryCaptureException(error, 'Patient file fetching alerts', userData);
        },
        [
          where('organisationUid', '==', userData?.organisationUid),
          where('patient.uid', '==', patient.uid),
          orderBy('alertDateTime', 'asc'),
        ]
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const typeTemplate = (alert: IPatientAlertDao) => {
    const match = PatientAlertTypeData[alert.type];
    return <p className='body-sm'>{t(match?.translationLabelKey ?? 'common.unknown')}</p>;
  };

  const statusTemplate = (alert: IPatientAlertDao) => {
    return (
      <Tag color={alert.acknowledged ? 'green' : 'red'}>
        {t(alert.acknowledged ? 'common.acknowledged' : 'common.unacknowledged')}
      </Tag>
    );
  };

  const actionsTemplate = (alert: IPatientAlertDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <PatientAlertAcknowledgementButton alert={alert} />
        {!alert.acknowledged && (
          <>
            <SharedElementPermissionGuard
              requiredPermissions={[[Permission.PATIENT_ALERTS_UPDATE], [Permission.ORGANISATION_OWNER]]}
            >
              <SharedButton
                onClick={() => dialog?.openDialog(<AddEditPatientAlertDialog patient={patient} existing={alert} />)}
                type='button'
                appearance='link'
                labelKey='common.edit'
                primaryOverride
              />
            </SharedElementPermissionGuard>
            <SharedElementPermissionGuard
              requiredPermissions={[[Permission.PATIENT_ALERTS_DELETE], [Permission.ORGANISATION_OWNER]]}
            >
              <SharedButton
                onClick={() =>
                  dialog?.openDialog(
                    <ConfirmActionDialog
                      action={() => PatientAlertsApiService.permDelete(alert.uid)}
                      actionButtonProps={{
                        labelKey: 'common.delete',
                        danger: true,
                      }}
                      title={t('patients.patient.alerts.delete_alert.title')}
                      textContent={t('patients.patient.alerts.delete_alert.content')}
                      successMessage={t('patients.patient.alerts.delete_alert.success')}
                      errorMessage={t('patients.patient.alerts.delete_alert.error')}
                    />
                  )
                }
                type='button'
                appearance='link'
                labelKey='common.delete'
                danger
              />
            </SharedElementPermissionGuard>
          </>
        )}
      </div>
    );
  };

  const lastUpdatedTemplate = (alert: IPatientAlertDao) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {t('common.at_by', {
          at: dayjs(alert.updated.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: alert.updated.by.fullName,
        })}
      </p>
    );
  };

  const createdTemplate = (alert: IPatientAlertDao) => {
    return (
      <p className='whitespace-pre-wrap body-sm'>
        {t('common.at_by', {
          at: dayjs(alert.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: alert.created.by.fullName,
        })}
      </p>
    );
  };

  return (
    <SharedCard
      title={t('patients.patient.alerts.title')}
      extra={
        <SharedButton
          labelKey='patients.patient.alerts.title.add_alert'
          appearance='link'
          primaryOverride
          onClick={() => dialog?.openDialog(<AddEditPatientAlertDialog patient={patient} />)}
        />
      }
    >
      <SharedTable
        loading={loading}
        rows={alerts.map((alert) => ({
          key: alert.uid,
          data: alert,
        }))}
        columns={[
          {
            labelKey: 'patients.patient.alerts.table.header.type',
            key: 'type',
            contentTemplateId: 'type',
          },
          {
            labelKey: 'patients.patient.alerts.table.header.alert_date_time',
            key: 'alertDateTime',
            contentTemplateId: 'alertDateTime',
            width: 150,
          },
          {
            labelKey: 'patients.patient.alerts.table.header.status',
            key: 'status',
            contentTemplateId: 'status',
          },
          {
            labelKey: 'patients.patient.alerts.table.header.updated',
            key: 'lastUpdated',
            contentTemplateId: 'updatedAt',
            width: 160,
          },
          {
            labelKey: 'patients.patient.alerts.table.header.created',
            key: 'created',
            contentTemplateId: 'createdAt',
            width: 160,
          },
          {
            key: 'actions',
            contentTemplateId: 'actions',
          },
        ]}
        contentTemplates={[
          {
            template: typeTemplate,
            id: 'type',
          },
          {
            template: (alert: IPatientAlertDao) => (
              <p className='body-sm'>{dayjs(alert.alertDateTime.toDate()).format('DD/MM/YYYY')}</p>
            ),
            id: 'alertDateTime',
          },
          {
            template: actionsTemplate,
            id: 'actions',
          },
          {
            template: statusTemplate,
            id: 'status',
          },
          {
            template: createdTemplate,
            id: 'updatedAt',
          },
          {
            template: lastUpdatedTemplate,
            id: 'createdAt',
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientAlertsList;
