import { IPatientDao } from 'core/api/types';
import PatientOverviewAppointmentsWidget from './patient-overview-appointments-widget';
import PatientFinancialWidget from '../patient-financials-widget';
import PatientOverviewLastTestWidget from './patient-overview-last-test-widget';
import PatientOverviewManufacturerWidget from './patient-overview-manufacturer-widget';
import PatientOverviewLastCommunicationWidget from './patient-overview-last-communication-widget';
import PatientOverviewLastTransactionsWidget from './patient-overview-last-transactions-widget';

const PatientOverview = (patient: IPatientDao) => {
  return (
    <div className='flex-col lg:flex-row flex lg:space-x-4'>
      <div className='lg:w-1/2'>
        <div className='grid xl:grid-cols-2 gap-x-4'>
          <PatientOverviewAppointmentsWidget {...patient} />
          <PatientFinancialWidget patient={patient} />
        </div>
        <div className='grid xl:grid-cols-2 gap-x-4'>
          <PatientOverviewManufacturerWidget {...patient} />
          <div>
            <PatientOverviewLastCommunicationWidget {...patient} />
            <PatientOverviewLastTransactionsWidget {...patient} />
          </div>
        </div>
      </div>

      <div className='lg:w-1/2 h-[800px]'>
        <PatientOverviewLastTestWidget {...patient} />
      </div>
    </div>
  );
};

export default PatientOverview;
