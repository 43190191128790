import { Navigate, Route, Routes } from 'react-router-dom';
import { Permission } from 'core/constants/permission';
import PermissionGuard from 'core/routing/permission-guard';
import RepairsList from './repairs-list';
import RepairDetails from './repair-details';

const RepairsRouting = () => {
  const routes = [
    {
      requiredPermissions: [[Permission.REPAIRS_BROWSE], [Permission.ORGANISATION_OWNER]],
      path: '',
      element: <RepairsList />,
    },
    {
      requiredPermissions: [[Permission.REPAIRS_READ], [Permission.ORGANISATION_OWNER]],
      path: ':uid',
      element: <RepairDetails />,
    },
  ];

  return (
    <Routes>
      {routes.map(({ requiredPermissions, path, element }) => (
        <Route
          key={path}
          element={
            <PermissionGuard
              requiredPermissions={requiredPermissions}
              navList={routes.map((route) => ({
                labelKey: '',
                requiredPermissions: route.requiredPermissions,
                route: `repairs/${route.path}`,
              }))}
            />
          }
        >
          <Route path={path} element={element} />
        </Route>
      ))}
      <Route path='*' element={<Navigate replace to='' />} />
    </Routes>
  );
};

export default RepairsRouting;
