import { App, Button } from 'antd';
import NoahLogo from 'assets/images/noah.png';
import { NoahPatientExportsApiService, NoahSyncActionApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { DocumentSnapshot, limit, orderBy, QuerySnapshot, where } from 'firebase/firestore';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import NoahSyncActionCreatedSuccessfullyDialog from './action-created-successfully-dialog';
import NoPatientRecordFoundDialog from './no-patient-record-found-dialog';

interface INoahLaunchButton {
  patient: IPatientDao;
  recordUid?: string;
  className?: string;
}

const NoahLaunchButton = ({ patient, recordUid, className }: INoahLaunchButton) => {
  const [loading, setLoading] = useState(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const dialog = useDialog();

  const handleClick = async () => {
    try {
      setLoading(true);
      if (!userData?.organisationUid) {
        throw new Error('Organisation UID not found');
      }
      let snap: DocumentSnapshot | QuerySnapshot;
      if (recordUid) {
        snap = await NoahPatientExportsApiService.get(recordUid);
      } else {
        snap = await NoahPatientExportsApiService.getAll([
          where('organisationUid', '==', userData.organisationUid),
          where('patientUid', '==', patient.uid),
          limit(1),
          orderBy('updated.at', 'desc'),
        ]);
      }

      const empty = snap instanceof DocumentSnapshot ? !snap.exists() : snap.empty;
      if (empty) {
        dialog?.openDialog(<NoPatientRecordFoundDialog {...patient} />);
      } else {
        const actionTimestamp = getActionTimestampFromUser(userData);
        await NoahSyncActionApiService.set({
          patientUid: patient.uid,
          ...(!empty && { patientRecordUid: snap instanceof DocumentSnapshot ? snap.id : snap.docs[0].id }),
          created: actionTimestamp,
          updated: actionTimestamp,
          organisationUid: userData.organisationUid,
          uid: uuidv4(),
        });
        dialog?.openDialog(<NoahSyncActionCreatedSuccessfullyDialog />);
      }
    } catch (error) {
      message.error(t('patients.patient.information.noah_launch.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button className={className ?? 'h-[34px] w-fit mt-4 md:mt-0'} onClick={handleClick} loading={loading}>
      <img src={NoahLogo} alt='noah' className='max-h-full py-1' />
    </Button>
  );
};

export default NoahLaunchButton;
