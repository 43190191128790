import { App, Dropdown, Tag } from 'antd';
import { IRepairDao } from 'core/api/types/repairs.interface';
import { RepairStatus, RepairStatusData, RepairStatusOptions } from 'core/constants/repair-status';
import { useTranslation } from 'react-i18next';
import { SyncOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { RepairsApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useAComponentPortal } from 'core/providers/component-portal-provider';

interface IRepairStatusTag {
  repair: IRepairDao | undefined;
  tableKey?: string;
}

const RepairStatusTag = ({ repair, tableKey }: IRepairStatusTag) => {
  const { t } = useTranslation();
  const status = repair?.status ? RepairStatusData[repair?.status] : undefined;
  const [loading, setLoading] = useState(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const table = useAComponentPortal(tableKey ?? '');

  const handleStatusChange = async (key: RepairStatus) => {
    setLoading(true);
    try {
      if (!repair?.uid) return;
      await RepairsApiService.update(repair?.uid, { status: key, updated: getActionTimestampFromUser(userData) });
      table?.exposedFunction();
      message.success(t('repair.repair_table.update.success'));
    } catch (error) {
      message.error(t('repair.repair_table.update.error'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dropdown
      menu={{
        items: RepairStatusOptions.filter((status) => status !== repair?.status).map((status) => {
          const statusData = RepairStatusData[status];
          return {
            key: status,
            label: t(statusData.translationLabelKey),
          };
        }),
        onClick: async ({ key }) => handleStatusChange(key as RepairStatus),
      }}
      trigger={['click']}
    >
      <Tag className='cursor-pointer' icon={loading && <SyncOutlined spin />} color={status?.color}>
        {t(status?.translationLabelKey ?? 'common.unknown')}
      </Tag>
    </Dropdown>
  );
};

export default RepairStatusTag;
