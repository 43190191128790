import { App, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { OrderApiService, StockApiService } from 'core/api';
import { IOrderDao } from 'core/api/types/order.interface';
import { PaymentMethod, PaymentMethodData, PaymentMethodOptions } from 'core/constants/payment-method';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SignatureCanvas from 'react-signature-canvas';
import { IStockDao } from 'core/api/types/stock.interface';
import { and, or, orderBy, Unsubscribe, where } from 'firebase/firestore';
import SharedButton from 'shared/button/button';
import { buildAccessoryString, buildAidString } from 'shared/helpers/product-string-builder';

type ConfirmationMode = 'signature' | 'manual' | 'none';

type ProductSelectionMode = 'select' | 'manual';

interface IGenerateFittingReceiptDialogFormOutput {
  [key: `${string}.serialNumber`]: string;
  [key: `${string}.warranty`]: string;
  [key: `${string}.product`]: string;
  amount?: number;
  fittingDate: dayjs.Dayjs;
  fittingPayment: boolean;
  method?: PaymentMethod;
  patientConfirmationMode?: ConfirmationMode;
  audiologistConfirmationMode?: ConfirmationMode;
  patientSignature?: SignatureCanvas;
  audiologistSignature?: SignatureCanvas;
  patientManualConfirmation?: string;
  audiologistManualConfirmation?: string;
  sendEmail: boolean;
  emailAddress?: string;
}

const GenerateFittingReceiptDialog = (order: IOrderDao) => {
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();
  const [form] = Form.useForm();
  const fittingPaymentEnabled = useWatch('fittingPayment', form);
  const patientConfirmationMode = useWatch('patientConfirmationMode', form);
  const audiologistConfirmationMode = useWatch('audiologistConfirmationMode', form);
  const sendEmail = useWatch('sendEmail', form);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const [assignedProducts, setAssignedProducts] = useState<IStockDao[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const ears = [
    {
      key: 'left',
      label: t('order.add_edit_order.hearing_aids.left'),
      aidDetails: order.hearingAids.left,
    },
    {
      key: 'right',
      label: t('order.add_edit_order.hearing_aids.right'),
      aidDetails: order.hearingAids.right,
    },
  ].filter((ear) => Boolean(ear.aidDetails));
  const accessories = order.accessoriesAndServices.filter((accessory) => accessory.type === 'accessory');
  const [productSelectionMode, setProductSelectionMode] = useState<Record<string, ProductSelectionMode>>(
    accessories.reduce(
      (acc: Record<string, ProductSelectionMode>, accessory) => {
        acc[accessory.uid] = 'select';
        return acc;
      },
      {
        left: 'select',
        right: 'select',
      }
    )
  );

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = StockApiService.onCollectionSnapshot(
      (snap) => {
        const data = snap.docs.map((doc) => doc.data());
        setAssignedProducts(data);
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.products.error'));
        sentryCaptureException(error, 'Patient file fetching transactions', userData);
      },
      [],
      {
        filter: and(
          where('organisationUid', '==', userData?.organisationUid),
          or(
            and(where('type', '==', 'hearingAid'), where('allocated.to.uid', '==', order.patient.uid)),
            and(where('type', '==', 'accessory'), where('allocation.patients', 'array-contains', order.patient.uid))
          )
        ),
        orderBy: orderBy('updated.at', 'desc'),
      }
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, order.patient.uid, t, userData]);

  const confirmationModeOptions = [
    {
      label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.confirmation_mode.signature'),
      value: 'signature',
    },
    {
      label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.confirmation_mode.manual'),
      value: 'manual',
    },
    { label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.confirmation_mode.none'), value: 'none' },
  ];

  const submit = async (data: IGenerateFittingReceiptDialogFormOutput) => {
    setSubmitting(true);
    const { audiologistSignature, patientSignature, fittingDate, ...rest } = data;

    const productKeys = ['left', 'right', ...accessories.map((accessory) => accessory.uid)];

    const productValues = productKeys.reduce((acc: Record<string, string | undefined>, key) => {
      if (productSelectionMode[key] === 'select') {
        const product = assignedProducts.find((assignedProduct) => assignedProduct.uid === rest[`${key}.product`]);
        acc[`${key}.serialNumber`] = product?.serialNumber;
        acc[`${key}.warranty`] = product?.warranty;
      } else {
        acc[`${key}.serialNumber`] = rest[`${key}.serialNumber`];
        acc[`${key}.warranty`] = rest[`${key}.warranty`];
      }
      return acc;
    }, {});

    try {
      await OrderApiService.generateFittingReceipt({
        ...productValues,
        ...rest,
        ...(audiologistSignature && { audiologistSignature: audiologistSignature.getTrimmedCanvas().toDataURL() }),
        ...(patientSignature && { patientSignature: patientSignature.getTrimmedCanvas().toDataURL() }),
        fittingDate: fittingDate.format('DD/MM/YYYY'),
        organisationUid: userData?.organisationUid!,
        orderUid: order.uid,
      });
      message.success(t('patients.patient.orders.generate_fitting_receipt_dialog.success'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('patients.patient.orders.generate_fitting_receipt_dialog.error'));
      setSubmitting(false);
      sentryCaptureException(error, 'Generating fitting receipt', userData);
    }
  };

  const getProductOptionList = (type: 'hearingAid' | 'accessory', side?: string) => {
    const ops = assignedProducts
      .filter(
        (product) =>
          product.type === type && (!side || (product.type === 'hearingAid' && product.allocated?.side === side))
      )
      .sort((a, b) => a.manufacturer.toLowerCase().localeCompare(b.manufacturer.toLowerCase()))
      .map((product) => {
        const values =
          product.type === 'hearingAid'
            ? [product.manufacturer, product.model, product.style, product.power, product.colour, product.serialNumber]
            : [product.manufacturer, product.accessoryName, product.serialNumber];
        return {
          label: values.filter((value) => Boolean(value)).join(' / '),
          value: product.uid,
        };
      });
    return ops;
  };

  const customContent = () => {
    return (
      <div className='overflow-y-auto'>
        {loading ? <></> : <></>}
        <SharedForm
          className='px-4 pt-4 pb-2 border-b'
          formInstance={form}
          fields={[
            {
              fieldKey: 'fittingDate',
              control: ControlType.DatePicker,
              label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.fitting_date'),
              required: true,
              fullWidth: true,
            },
          ]}
          submitting={submitting}
          name='fitting-receipt-fitting-date'
          buttonsOverride={[]}
          existingValue={{ fittingDate: dayjs() }}
        />
        {ears.map((ear) => (
          <div key={ear.key} className='px-4 pt-4 pb-2 border-b'>
            <p className='mb-3 font-semibold'>
              {ear.label} <span className='font-light'>({buildAidString(ear.aidDetails!)})</span>
            </p>
            <SharedForm
              initializing={loading}
              formInstance={form}
              className=''
              fields={[
                {
                  fieldKey: `${ear.key}.serialNumber`,
                  control: ControlType.TextField,
                  type: InputType.Text,
                  label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.serial_number'),
                  required: false,
                  hidden: productSelectionMode[ear.key] !== 'manual',
                },
                {
                  fieldKey: `${ear.key}.warranty`,
                  control: ControlType.TextField,
                  type: InputType.Text,
                  label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.warranty'),
                  required: false,
                  hidden: productSelectionMode[ear.key] !== 'manual',
                },
                {
                  fieldKey: `${ear.key}.product`,
                  control: ControlType.Select,
                  options: getProductOptionList('hearingAid', ear.key),
                  label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.product'),
                  required: true,
                  hidden: productSelectionMode[ear.key] !== 'select',
                },
              ]}
              submitting={submitting}
              name={`fitting-receipt-aid-details-${ear.key}`}
              buttonsOverride={[]}
            />
            <SharedButton
              appearance='link'
              primaryOverride
              labelKey={
                productSelectionMode[ear.key] === 'select'
                  ? 'patients.patient.orders.generate_fitting_receipt_dialog.form.product.enter_manually'
                  : 'patients.patient.orders.generate_fitting_receipt_dialog.form.product.select'
              }
              onClick={() =>
                setProductSelectionMode((prev) => ({
                  ...prev,
                  [ear.key]: prev[ear.key] === 'select' ? 'manual' : 'select',
                }))
              }
            />
          </div>
        ))}
        {accessories.map((accessory) => (
          <div key={accessory.uid} className='px-4 pt-4 pb-2 border-b'>
            <p className='mb-3 font-semibold'>
              Accessory <span className='font-light'>({buildAccessoryString(accessory)})</span>
            </p>
            <SharedForm
              initializing={loading}
              formInstance={form}
              className=''
              fields={[
                {
                  fieldKey: `${accessory.uid}.serialNumber`,
                  control: ControlType.TextField,
                  type: InputType.Text,
                  label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.serial_number'),
                  required: false,
                  hidden: productSelectionMode[accessory.uid] !== 'manual',
                },
                {
                  fieldKey: `${accessory.uid}.warranty`,
                  control: ControlType.TextField,
                  type: InputType.Text,
                  label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.warranty'),
                  required: false,
                  hidden: productSelectionMode[accessory.uid] !== 'manual',
                },
                {
                  fieldKey: `${accessory.uid}.product`,
                  control: ControlType.Select,
                  options: getProductOptionList('accessory'),
                  label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.product'),
                  required: true,
                  hidden: productSelectionMode[accessory.uid] !== 'select',
                },
              ]}
              submitting={submitting}
              name={`fitting-receipt-accessory-details-${accessory.uid}`}
              buttonsOverride={[]}
            />
            <SharedButton
              appearance='link'
              primaryOverride
              labelKey={
                productSelectionMode[accessory.uid] === 'select'
                  ? 'patients.patient.orders.generate_fitting_receipt_dialog.form.product.enter_manually'
                  : 'patients.patient.orders.generate_fitting_receipt_dialog.form.product.select'
              }
              onClick={() =>
                setProductSelectionMode((prev) => ({
                  ...prev,
                  [accessory.uid]: prev[accessory.uid] === 'select' ? 'manual' : 'select',
                }))
              }
            />
          </div>
        ))}
        <SharedForm
          onFinish={submit}
          formInstance={form}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          fields={[
            {
              fieldKey: 'fittingPayment',
              control: ControlType.Switch,
              label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.payment_on_fitting'),
              required: true,
            },
            {
              fieldKey: 'amount',
              control: ControlType.NumberField,
              min: 1,
              fullWidth: true,
              label: t('patients.patient.transactions.add_edit_transaction.form.amount'),
              required: true,
              hidden: fittingPaymentEnabled === false,
            },
            {
              fieldKey: 'method',
              control: ControlType.Select,
              options: PaymentMethodOptions.map((method) => {
                const data = PaymentMethodData[method];
                return { label: t(data.translationLabelKey), value: method };
              }),
              label: t('patients.patient.transactions.add_edit_transaction.form.method'),
              required: true,
              hidden: fittingPaymentEnabled === false,
            },
            {
              fieldKey: 'patientConfirmationMode',
              control: ControlType.RadioButton,
              options: confirmationModeOptions,
              label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.patient_confirmation_mode'),
              required: true,
            },
            {
              fieldKey: 'patientSignature',
              control: ControlType.Signature,
              required: true,
              hidden: patientConfirmationMode !== 'signature',
            },
            {
              fieldKey: 'patientManualConfirmation',
              control: ControlType.TextField,
              type: InputType.Text,
              required: true,
              hidden: patientConfirmationMode !== 'manual',
            },
            {
              fieldKey: 'audiologistConfirmationMode',
              control: ControlType.RadioButton,
              options: confirmationModeOptions,
              label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.audiologist_confirmation_mode'),
              required: true,
            },
            {
              fieldKey: 'audiologistSignature',
              control: ControlType.Signature,
              required: true,
              hidden: audiologistConfirmationMode !== 'signature',
            },
            {
              fieldKey: 'audiologistManualConfirmation',
              control: ControlType.TextField,
              type: InputType.Text,
              required: true,
              hidden: audiologistConfirmationMode !== 'manual',
            },
            {
              fieldKey: 'sendEmail',
              control: ControlType.Switch,
              label: t('patients.patient.orders.generate_fitting_receipt_dialog.form.send_email'),
              required: true,
            },
            {
              fieldKey: 'emailAddress',
              control: ControlType.TextField,
              type: InputType.Email,
              required: sendEmail,
              hidden: !sendEmail,
              placeholder: t('common.email_address'),
            },
          ]}
          submitting={submitting}
          name='fitting-receipt-payment'
          existingValue={{
            fittingPayment: false,
            patientConfirmationMode: 'signature',
            audiologistConfirmationMode: 'signature',
            sendEmail: false,
            emailAddress: order.patient.emailAddress,
          }}
        />
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.patient.orders.generate_fitting_receipt_dialog.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default GenerateFittingReceiptDialog;
