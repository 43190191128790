import { IAppointmentDao } from 'core/api/types/appointment.interface';
import CalendarDailyColumn, { ISharedCalendarColumnResource } from './calendar-daily-column';
import {
  IDomainCalendarResource,
  IDomainOrganisationDataType,
} from 'modules/organisation-settings/organisation-settings-slice';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'antd';
import { CalendarMode } from 'core/constants/calendar-mode';
import { IClinicDao } from 'core/api/types';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { ISharedCalendarNewAppointment } from '../calendar';
import { CalendarTimeframe } from 'core/constants/calendar-timeframe';
import { useUserState } from 'core/providers/user-provider';

interface ISharedCalendar {
  timeSlots: string[];
  appointments: IAppointmentDao[];
  unavailability: IHolidayAndUnavailabilityDao[];
  people: IDomainCalendarResource[];
  clinics: IDomainOrganisationDataType<IClinicDao>[];
  highlightedPerson?: string;
  highlightedClinic?: string;
  currentDate: Dayjs;
  loading?: boolean;
  newAppointment?: ISharedCalendarNewAppointment;
  startHour: number;
  showAppointmentMenu?: boolean;
  calendarWrapperRef: React.RefObject<HTMLDivElement>;
  mode: CalendarMode;
  timeframe: CalendarTimeframe;
  zoom: number;
  enabledDays: number[];
}

const SharedCalendarDaily = ({
  timeSlots,
  appointments,
  unavailability,
  people,
  highlightedPerson,
  highlightedClinic,
  currentDate,
  loading,
  newAppointment,
  startHour,
  showAppointmentMenu,
  calendarWrapperRef,
  mode,
  clinics,
  zoom,
  timeframe,
  enabledDays,
}: ISharedCalendar) => {
  const headerHeight = 56;
  const resourceHeaderHeight = 56;
  const dayHeaderHeight = timeframe === CalendarTimeframe.WEEK ? 46 : 0;
  const minimumSlotHeight = 80;
  const [calendarContainerHeight, setCalendarContainerHeight] = useState<number>(0);
  const [timeSlotHeight, setTimeSlotHeight] = useState<number>(minimumSlotHeight);
  const calendarBodyHeight = timeSlotHeight * timeSlots.length + resourceHeaderHeight + dayHeaderHeight;
  const { t } = useTranslation();
  const [days, setDays] = useState<Dayjs[]>([currentDate]);
  const { organisationData } = useUserState();
  const calendarOrder = organisationData?.calendar.order ?? [];

  useEffect(() => {
    if (timeframe === CalendarTimeframe.WEEK) {
      const weekDates = [];
      for (let i = 0; i < 7; i++) {
        weekDates.push(currentDate.startOf('week').add(i, 'day'));
      }

      setDays(weekDates.filter((date) => enabledDays.includes(date.day())));
    } else {
      setDays([currentDate]);
    }
  }, [currentDate, timeframe, enabledDays]);

  useEffect(() => {
    if (!calendarWrapperRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setCalendarContainerHeight(calendarWrapperRef.current?.clientHeight ?? 0);
    });
    resizeObserver.observe(calendarWrapperRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  });

  useEffect(() => {
    const calendarHeight =
      calendarContainerHeight - resourceHeaderHeight - dayHeaderHeight - headerHeight - (loading ? 4 : 0);
    if (calendarHeight / timeSlots.length < minimumSlotHeight) {
      setTimeSlotHeight(minimumSlotHeight);
    } else {
      setTimeSlotHeight((calendarHeight - (loading ? 4 : 0)) / timeSlots.length);
    }
  }, [calendarContainerHeight, dayHeaderHeight, loading, timeSlots.length]);

  const currentTimeLinePosition = () => {
    const minuteHeight = timeSlotHeight / 60;
    const now = dayjs();
    const calculatedMargin =
      resourceHeaderHeight + dayHeaderHeight + (now.hour() - startHour) * timeSlotHeight + now.minute() * minuteHeight;
    if (calculatedMargin <= resourceHeaderHeight + dayHeaderHeight) {
      return `${resourceHeaderHeight + dayHeaderHeight}px`;
    }
    if (calculatedMargin > calendarBodyHeight) {
      return `${calendarBodyHeight - 1}px`;
    }
    return `${calculatedMargin}px`;
  };

  const columns =
    mode === CalendarMode.PEOPLE
      ? people.sort(
          (a, b) => calendarOrder.findIndex((uid) => a.uid === uid) - calendarOrder.findIndex((uid) => b.uid === uid)
        )
      : clinics;

  const getResource = (
    column: IDomainCalendarResource | IDomainOrganisationDataType<IClinicDao>
  ): ISharedCalendarColumnResource => {
    if (mode === CalendarMode.PEOPLE) {
      const data = column as IDomainCalendarResource;
      return {
        uid: data.uid,
        label: data.fullName,
        type: 'person',
        details: data,
      };
    } else {
      const data = column as IDomainOrganisationDataType<IClinicDao>;
      return {
        uid: data.uid,
        label: data.name,
        type: 'clinic',
        details: data,
      };
    }
  };

  return (
    <div className='flex grow overflow-y-auto'>
      <div
        className='basis-[56px] shrink-0 grow-0 pr-[8px] border-r bg-white sticky left-0 z-40'
        style={{ height: calendarBodyHeight }}
      >
        <div style={{ height: `${resourceHeaderHeight + dayHeaderHeight}px` }} />
        {timeSlots.map((slot) => (
          <div className='relative' style={{ height: `${timeSlotHeight}px` }} key={slot}>
            <p className='text-xs text-gray-500 -top-[8px] absolute right-0'>{slot}</p>
          </div>
        ))}
      </div>

      <div className='grow flex relative bg-white' style={{ height: calendarBodyHeight }}>
        <div className='grow flex'>
          {columns.length === 0 && !loading ? (
            <Alert className='self-start m-4 w-full' message={t('calendar.no_resources')} type='info' showIcon />
          ) : (
            days.map((day) => (
              <div
                className='grow flex flex-col border-r last:border-r-0 border-gray-400'
                key={day.format('YYYY-MM-DD')}
              >
                {timeframe === CalendarTimeframe.WEEK && (
                  <div
                    className={`w-full flex items-center justify-center border-b bg-gray-50 body-sm`}
                    style={{
                      flexBasis: `${dayHeaderHeight}px`,
                    }}
                  >
                    {day.format('ddd, D MMM')}
                  </div>
                )}
                <div className='grow flex'>
                  {timeframe === CalendarTimeframe.DAY && dayjs().isSame(currentDate, 'day') && columns.length > 0 && (
                    <div
                      className='absolute w-full h-px bg-red-600 z-40'
                      style={{
                        top: currentTimeLinePosition(),
                      }}
                    />
                  )}
                  {columns.map((column) => (
                    <CalendarDailyColumn
                      key={column.uid}
                      currentDate={currentDate}
                      resource={getResource(column)}
                      existingAppointments={appointments.filter((app) => {
                        if (!dayjs(app.startDateTime.toDate()).isSame(day, 'day')) {
                          return false;
                        }
                        if (mode === CalendarMode.PEOPLE) {
                          return app.assignee.uid === column.uid;
                        }
                        return app.clinic === column.uid;
                      })}
                      unavailability={unavailability.filter(
                        (u) => u.assignee.uid === column.uid && dayjs(u.startDateTime.toDate()).isSame(day, 'day')
                      )}
                      timeSlots={timeSlots}
                      timeSlotHeight={timeSlotHeight}
                      resourceHeaderHeight={resourceHeaderHeight}
                      showAppointmentMenu={showAppointmentMenu}
                      mode={mode}
                      timeframe={timeframe}
                      zoom={zoom}
                      {...(currentDate.isSame(day, 'day') && {
                        newAppointment,
                        highlightedClinic,
                        highlightedPerson,
                      })}
                      actualDay={day}
                    />
                  ))}
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SharedCalendarDaily;
