import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

interface ICalendarEnabledDaysField {
  value: number[];
  onChange: (value: number[]) => void;
}

const CalendarEnabledDaysField = ({ value, onChange }: ICalendarEnabledDaysField) => {
  const { t } = useTranslation();

  return (
    <div className='p-4 flex justify-between items-center border-b'>
      <p>{t('calendar.enabled_days')}</p>

      <Checkbox.Group
        className='flex-col md:flex-row'
        value={value}
        onChange={onChange}
        options={[1, 2, 3, 4, 5, 6, 0].map((day) => ({
          label: t(`day.short.${day}`),
          value: day,
        }))}
      />
    </div>
  );
};

export default CalendarEnabledDaysField;
