import { App } from 'antd';
import { RepairsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn } from 'shared/table/table.interface';
import { IRepairDao } from 'core/api/types/repairs.interface';
import { orderBy, where } from 'firebase/firestore';
import RepairStatusTag from 'modules/repairs/repair-status-tag';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditRepairNote from 'modules/repairs/add-edit-repair-note-dialog';
import AddEditRepairDialog from 'modules/repairs/add-edit-repair-dialog';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'patients.patient.repairs.table.header.manufacturer',
    key: 'manufacturer',
    width: 160,
  },
  {
    labelKey: 'patients.patient.repairs.table.header.products',
    key: 'products',
    contentTemplateId: 'products',
    width: 300,
  },
  {
    labelKey: 'patients.patient.repairs.table.header.status',
    key: 'status',
    contentTemplateId: 'status',
    width: 250,
  },
  {
    labelKey: 'patients.patient.repairs.table.header.last_updated',
    key: 'updated',
    contentTemplateId: 'lastUpdated',
    width: 150,
  },
  {
    key: 'actions',
    contentTemplateId: 'actions',
    width: 120,
  },
];

const PatientRepairsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [repairs, setRepairs] = useState<IRepairDao[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = RepairsApiService.onCollectionSnapshot(
      (snap) => {
        setLoading(false);
        setRepairs(snap.docs.map((doc) => doc.data()));
      },
      (error) => {
        message.error(t('patients.patient.repairs.error'));
        sentryCaptureException(error, 'Patient file fetching repairs', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patient.uid', '==', patient.uid),
        orderBy('updated.at', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const productsTemplate = (repair: IRepairDao) => {
    return (
      <div className='flex flex-col space-y-3'>
        {repair.products.map((product) => (
          <p className='text-wrap body-xs' key={product.uid}>{`${product.description} ${
            product.serialNumber && `/ ${product.serialNumber}`
          }`}</p>
        ))}
      </div>
    );
  };

  const statusTemplate = (repair: IRepairDao) => <RepairStatusTag repair={repair} />;

  const actionsTemplate = (repair: IRepairDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.REPAIRS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            appearance='link'
            primaryOverride
            labelKey='patients.patient.repairs.table.action.add_note'
            onClick={() => dialog?.openDialog(<AddEditRepairNote repair={repair} />)}
          />
        </SharedElementPermissionGuard>
        <Link to={`/repairs/${repair.uid}`}>
          <SharedButton labelKey='common.view' appearance='link' primaryOverride />
        </Link>
      </div>
    );
  };

  return (
    <SharedCard
      title={t('patients.patient.repairs.title')}
      extra={
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.REPAIRS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='repairs.repairs_list.header.create_repair'
            onClick={() =>
              dialog?.openDialog(
                <AddEditRepairDialog
                  patient={{
                    uid: patient.uid,
                    fullName: patient.fullName,
                  }}
                />
              )
            }
            appearance='link'
            primaryOverride
          />
        </SharedElementPermissionGuard>
      }
    >
      <SharedTable
        loading={loading}
        rows={repairs.map((item, index) => ({
          key: `${item.uid}-${index}`,
          data: item,
        }))}
        columns={tableColumns}
        contentTemplates={[
          {
            id: 'products',
            template: productsTemplate,
          },
          {
            id: 'status',
            template: statusTemplate,
          },
          {
            id: 'actions',
            template: actionsTemplate,
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientRepairsList;
